import {useEffect, useState} from 'react';
import {Heading} from '../../../Components/Heading/Heading';
import {TopupReportPayloadDTO} from '../../../Models/TopupReport/TopupReport';
import RevenueReportExportBtn from './RevenueReportExport/RevenueReportExportBtn';
import RevenueReportFilter from './RevenueReportFilter/RevenueReportFilter';
import RevenueReportGrid from './RevenueReportGrid/RevenueReportGrid';
import './RevenueReport.scss';
import {PaginationResponseDto} from "../../../Models/PaginationResponseDto";
import TopupReport from "../../../Services/TopupReport/TopupReport";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";

type Props = {};

export const RevenueReportReport = (props: Props) => {
    const [revenueReportData, setRevenueReportData] = useState<any>([]);
    const [searchKey, setSearchKey] = useState<string>('');

    const [paginationData, setPaginationData] = useState<PaginationResponseDto>({
        totalPages: 0,
        pageNumber: 1,
    });

    const [revenueReportPayload, setRevenueReportPayload] = useState<TopupReportPayloadDTO>({
        pageSize: 10,
        userExternalIds: [],
        taxCountries: [],
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
        endDate: new Date().toISOString(),
        transactionStatusIds: [3],
    });

    const [Loading, setLoading] = useState(false);

    const handleFilterRevenueReport = (pageNumber: number = 1) => {
        setLoading(true);

        const payload: TopupReportPayloadDTO = {
            userExternalIds: revenueReportPayload.userExternalIds,
            taxCountries: revenueReportPayload.taxCountries,
            startDate: revenueReportPayload.startDate,
            endDate: revenueReportPayload.endDate,
            keyword: searchKey,
            transactionStatusIds: revenueReportPayload.transactionStatusIds,
            pageNumber: pageNumber,
        }

        TopupReport.getReport(payload)
            .then((res) => {
                setRevenueReportData(res.result);

                setPaginationData({
                    ...paginationData,
                    totalRecords: res?.totalItemCount,
                    totalPages: res?.totalPages
                });

                setLoading(false);
            })
            .catch((err) => {
                notify('Error while fetching Revenue', 'error', TOASTER_DELAY);
                setLoading(false);
            });
    };

    useEffect(() => {
        handleFilterRevenueReport();
    }, [searchKey]);

    return (
        <>
            <section className="topup">
                <div
                    style={{
                        display: 'flex',
                        gap: '1.5rem',
                        alignItems: 'center',
                        margin: '1rem 0 1.5rem',
                    }}
                >
                    <Heading
                        level={2}
                        textContent="Revenue Report"
                        style={{margin: '0'}}
                    />
                    {revenueReportData.length > 0 && (
                        <RevenueReportExportBtn revenueReportPayload={revenueReportPayload}/>
                    )}
                </div>

                <RevenueReportFilter
                    revenueReportPayload={revenueReportPayload}
                    setRevenueReportPayload={setRevenueReportPayload}
                    loading={Loading}
                    handleFilterRevenueReport={handleFilterRevenueReport}
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                />

                <RevenueReportGrid
                    revenueReportData={revenueReportData}
                    paginationData={paginationData}
                    handleFilterRevenueReport={handleFilterRevenueReport}
                />
            </section>
        </>
    );
};

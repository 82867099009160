const { REACT_APP_ENV, REACT_APP_VERSION, REACT_APP_PAYMENT_MODEL } = (window as any).__env__;

type Environment = {
  REACT_APP_IDENTITYSERVER_API_BASE_URL: string;
  REACT_APP_AUTH_API_BASE_URL: string;
  REACT_APP_TIPS_API_BASE_URL: string;
  REACT_APP_ACCOUNTING_API_BASE_URL: string;
  REACT_APP_PROFILE_BASE_URL: string;
  CLIENT_SECRET: string;
  REACT_APP_REPORTING_BASE_URL: string;
  REACT_APP_VERSION: string;
  REACT_APP_PAYMENT_MODEL: string;
};

const envConfig: Environment = {
  REACT_APP_IDENTITYSERVER_API_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://auth-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://auth-api-dev.inplaytip.com/'
      : 'https://auth-api.inplaytip.com/',

  REACT_APP_ACCOUNTING_API_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://acc-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://acc-api-dev.inplaytip.com/'
      : 'https://acc-api.inplaytip.com/',

  REACT_APP_AUTH_API_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://auth-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://auth-api-dev.inplaytip.com/'
      : 'https://auth-api.inplaytip.com/',

  REACT_APP_TIPS_API_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://tips-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://tips-api-dev.inplaytip.com/'
      : 'https://tips-api.inplaytip.com/',

  REACT_APP_PROFILE_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://pm-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://pm-api-dev.inplaytip.com/'
      : 'https://pm-api.inplaytip.com/',

  REACT_APP_REPORTING_BASE_URL:
    REACT_APP_ENV == 'staging'
      ? 'https://reporting-api-staging.inplaytip.com/'
      : REACT_APP_ENV == 'dev'
      ? 'https://reporting-api-dev.inplaytip.com/'
      : 'https://reporting-api.inplaytip.com/',

  CLIENT_SECRET:
    'aW5wbGF5dGlwc2Ntczp7RTVFODhFMTQtNUEzQy0xNDVELUE3NTItRUEzNjdBNTVCMjNafQ==',
  REACT_APP_VERSION: REACT_APP_VERSION,
  REACT_APP_PAYMENT_MODEL: REACT_APP_PAYMENT_MODEL
};

export default envConfig;

import FactoryService from "../FactoryService";
import ProfileBaseService from "../ProfileBaseService";

const factoryService = new FactoryService();

class BlackoutService {
    private BLACKOUT_ENDPOINT = "api/BlackoutDefault";
    private CLEAR_CACHE = "api/Status/cache/reset";

    async getBlackout() {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).getAsJson(this.BLACKOUT_ENDPOINT, true);
        if (result) {
            return result;
        }
        return null;
    }

    async AddBlackout(data: any) {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).postAsJson(this.BLACKOUT_ENDPOINT, data, true);
        if (result) {
            return result;
        }
        return null;
    }

    async UpdateBlackout(data: any, id: any) {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).patchAsJson(`${this.BLACKOUT_ENDPOINT}/${id}`, data, true);
        if (result) {
            return result;
        }
        return null;
    }

    async DeleteBlackout(id: any) {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).delete(this.BLACKOUT_ENDPOINT + "/" + id, true);
        if (result) {
            return result;
        }
        return null;
    }

    async ClearProfileCache() {
        const result = await (
            await factoryService.create(ProfileBaseService)
        ).postAsJson(this.CLEAR_CACHE, {}, true);
        if (result) {
            return result;
        }
        return null;
    }

}

export default new BlackoutService();

import {useEffect, useState} from "react";

import RuleService from "../../../Services/RuleEngine/RuleService";
import {
    Button,
    CheckBox,
    LoadPanel,
    SelectBox,
    TextArea,
    TextBox,
} from "devextreme-react";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";

import "./Rules.scss";
import AddRule from "./addRule/AddRule";

type RuleType = {
    name: string;
    regularExpression: string;
    verificationRegularExpression: string;
    odd: string;
    minOdd: string;
    templateName: string;
    inSimulationMode: boolean;
}

const Rules = () => {
    const [rules, setRules] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRule, setSelectedRule] = useState<RuleType>({
        name: "",
        regularExpression: "",
        verificationRegularExpression: "",
        odd: "",
        minOdd: "",
        templateName: "",
        inSimulationMode: false,
    });
    const [isEditable, setIsEditble] = useState<boolean>(false);
    const [isAddRule, setIsAddRule] = useState<boolean>(false)

    const getRules = () => {
        setLoading(true);
        RuleService.getRules()
            .then((res) => {
                setRules(res);
                setLoading(false);
            })
            .catch((err) => {
                notify("Failed to get rules", "error", TOASTER_DELAY);
                setLoading(false);
            });
    };

    const sendSimulatedMessage = () => {
        setLoading(true)
        RuleService.sendSimulatedMessage(selectedRule.name).then((res) => {
            setLoading(false);
            notify("Simulated message sent", "success", TOASTER_DELAY);
        }).catch(() => {
            setLoading(false)
            notify("Failed to send simulated message", "error", TOASTER_DELAY);
        })
    }

    const betsRuleSelectionChanged = (ruleName: any) => {
        const rule = rules[0].betsRules.filter(
            (r: { name: any }) => r.name === ruleName
        );

        setSelectedRule(rule[0]);

        return;
    };

    const updateRuleHandler = (updatedRule: any) => {
        setLoading(true);
        RuleService.updateRules(updatedRule)
            .then((res) => {
                getRules();
                notify("Rules updated", "success", TOASTER_DELAY);
                setLoading(false);
                setIsEditble(false);
                setIsAddRule(false);
            })
            .catch((err) => {
                console.log(err);
                notify("Failed to update rules", "error", TOASTER_DELAY);
                setLoading(false);
            });
    };

    const editRuleHandler = () => {
        if (!selectedRule) {
            notify("Please select a rule", "error", TOASTER_DELAY);
            return;
        }

        if (
            !selectedRule.name ||
            !selectedRule.odd ||
            !selectedRule.regularExpression ||
            !selectedRule.verificationRegularExpression ||
            !selectedRule.templateName
        ) {
            notify("Please fill all fields", "error", TOASTER_DELAY);
            return;
        }

        if (selectedRule.templateName.length < 5) {
            notify("Template name must be at least 5 characters", "error", TOASTER_DELAY);
            return;
        }

        if (!/^\d*\.?\d*$/.test(selectedRule.odd)) {
            notify("Odd must be a valid number", "error", TOASTER_DELAY);
            return;
        }

        if (selectedRule.name.length < 5) {
            notify("Name must be at least 5 characters", "error", TOASTER_DELAY);
            return;
        }

        if (selectedRule.name.length > 100) {
            notify(
                "Name must not be more than 100 characters",
                "error",
                TOASTER_DELAY
            );
            return;
        }

        const ruleIndex = rules[0].betsRules.findIndex(
            (r: { name: any }) => r.name === selectedRule.name
        );

        let updatedRules = [...rules[0].betsRules];

        if (ruleIndex !== -1) {
            updatedRules[ruleIndex] = selectedRule;

            const updatedRule = {
                ...rules[0],
                betsRules: updatedRules,
            };

            updateRuleHandler(updatedRule);
            return;
        } else {
            updatedRules.push(selectedRule);

            const updatedRule = {
                ...rules[0],
                betsRules: updatedRules,
            };

            updateRuleHandler(updatedRule);
            return;
        }
    };

    useEffect(() => {
        getRules();
    }, []);

    return (
        <div>
            <h1>Rules</h1>
            <div>Manage, add and edit rules here.</div>

            {!loading &&
                (rules.length ? (
                    <div className="rule-container">
                        <div className="rule-status-container">
                            <p className="font-1rem">
                                <strong>Rule Id:</strong>
                                {` ${rules[0].id || ""}`}
                            </p>
                            <p className="font-1rem">
                                <strong>Status:</strong>
                                <span className="font-success">{` ${rules[0].statusStr || ""}`}</span>
                            </p>
                        </div>

                        {isEditable && (
                            <div className="rule-edit-alert">
                                <p>
                                    <strong>Info: </strong>
                                    If you change the name of the rule, a new rule will be
                                    created. However, if you modify the rule details without
                                    changing the name, the existing rule will be updated.
                                </p>
                            </div>
                        )}

                        {!isAddRule ?
                            <div className="rule-form-container">
                                <label className="form-label">
                                    Rule Name:
                                </label>
                                <div className="rule-name-input">
                                    {!isEditable ? (
                                        <SelectBox
                                            valueExpr="name"
                                            displayExpr="name"
                                            searchEnabled={true}
                                            value={selectedRule?.name || ""}
                                            placeholder="Select a rule"
                                            dataSource={rules[0].betsRules}
                                            onValueChanged={(e) => {
                                                betsRuleSelectionChanged(e.value);
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    ) : (
                                        <TextBox
                                            readOnly={!isEditable}
                                            value={selectedRule?.name || ""}
                                            style={{
                                                width: "100%",
                                                backgroundColor: isEditable ? "#fff" : "#eee",
                                            }}
                                            onValueChanged={(e) => {
                                                setSelectedRule({
                                                    ...selectedRule,
                                                    name: e.value,
                                                });
                                            }}
                                        />
                                    )}
                                </div>

                                <label className="form-label">
                                    Odd:
                                </label>
                                <TextBox
                                    readOnly={!isEditable}
                                    value={selectedRule?.odd || ""}
                                    style={{
                                        width: "100%",
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            odd: e.value,
                                        });
                                    }}
                                />

                                <label className="form-label">
                                    Minimum Odd:
                                </label>
                                <TextBox
                                    readOnly={!isEditable}
                                    value={selectedRule?.minOdd || ""}
                                    style={{
                                        width: "100%",
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            minOdd: e.value,
                                        });
                                    }}
                                />

                                <label className="form-label">
                                    Template Name:
                                </label>
                                <TextBox
                                    readOnly={!isEditable}
                                    value={selectedRule?.templateName || ""}
                                    style={{
                                        width: "100%",
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            templateName: e.value,
                                        });
                                    }}
                                />

                                <label className="form-label">
                                    Expression:
                                </label>
                                <TextArea
                                    height={220}
                                    value={selectedRule?.regularExpression || ""}
                                    readOnly={!isEditable}
                                    className="expression-textarea"
                                    style={{
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            regularExpression: e.value,
                                        });
                                    }}
                                />

                                <label className="form-label">
                                    Verification:
                                </label>
                                <TextArea
                                    height={220}
                                    value={selectedRule?.verificationRegularExpression || ""}
                                    readOnly={!isEditable}
                                    className="expression-textarea"
                                    style={{
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            verificationRegularExpression: e.value,
                                        });
                                    }}
                                />

                                <label className="form-label">
                                    Simulation Mode:
                                </label>
                                <CheckBox
                                    readOnly={!isEditable}
                                    value={selectedRule?.inSimulationMode || false}
                                    style={{
                                        backgroundColor: isEditable ? "#fff" : "#eee",
                                    }}
                                    text={`In simulation mode (${selectedRule?.inSimulationMode ? "Yes" : "No"})`}
                                    onValueChanged={(e) => {
                                        if (!isEditable) return;

                                        setSelectedRule({
                                            ...selectedRule,
                                            inSimulationMode: e.value,
                                        });
                                    }}
                                />

                                <div>
                                    {!isEditable ? (<>
                                            <Button
                                                type="default"
                                                style={{
                                                    marginTop: "1rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                text="Send Simulated Message"
                                                disabled={!selectedRule?.name || loading}
                                                onClick={() => sendSimulatedMessage()}
                                            />
                                            <Button
                                                type="default"
                                                style={{
                                                    marginTop: "1rem",
                                                    marginRight: "0.5rem",
                                                }}
                                                text="Edit"
                                                disabled={!selectedRule?.name || loading}
                                                onClick={() => setIsEditble(true)}
                                            />
                                            <Button
                                                type="default"
                                                style={{
                                                    marginTop: "1rem",
                                                }}
                                                text="Add"
                                                onClick={() => setIsAddRule(true)}
                                            />
                                        </>
                                    ) : (
                                        <div>
                                            <Button
                                                type="default"
                                                text="Save"
                                                style={{
                                                    marginTop: "1rem",
                                                }}
                                                onClick={() => editRuleHandler()}
                                            />
                                            <Button
                                                type="danger"
                                                text="Cancel"
                                                style={{
                                                    marginTop: "1rem",
                                                    marginLeft: "1rem",
                                                }}
                                                onClick={() => {
                                                    setIsEditble(false);
                                                    //if the rule name is not changed the selected rule will be the same and reverted to the original
                                                    //if not the selection will me empty
                                                    const rule = rules[0].betsRules.filter(
                                                        (r: { name: any }) => r.name === selectedRule.name
                                                    );

                                                    setSelectedRule(rule[0]);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div> :
                            <AddRule
                                setIsAddRule={setIsAddRule}
                                rules={rules}
                                updateRuleHandler={updateRuleHandler}
                            />
                        }
                    </div>
                ) : (
                    <div className="no-rule-found">
                        No rules found
                    </div>
                ))}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: "#simulations"}}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>
    );
};

export default Rules;

import {useEffect, useState} from "react";
import ReactJson from "react-json-view";
import SimulationService from "../../../Services/RuleEngine/SimulationService";
import {Button, LoadPanel, SelectBox, TextArea} from "devextreme-react";

import notify from "devextreme/ui/notify";

import {TOASTER_DELAY} from "../../../Constants/values";

import "./Simulations.scss";

type appliedBetsType = {
    bets: string;
    verifications: string;
};

const Simulations = () => {
    const [simulations, setSimulations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSimulation, setSelectedSimulation] = useState<any>("{}");
    const [appliedBets, setAppliedBets] = useState<appliedBetsType>({
        bets: "",
        verifications: "",
    });
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const getSimulations = () => {
        setLoading(true);
        SimulationService.getMatchSimulator()
            .then((res) => {
                setSimulations(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify("Failed to get simulations", "error", TOASTER_DELAY);
                setLoading(false);
            });
    };

    const selectedSimulationHandler = (id: number) => {
        if (!id) return;

        const simulation = simulations.filter((s) => s.id === id);
        setSelectedSimulation(JSON.stringify(simulation[0]));
        return;
    };

    const runSimulationHandler = () => {
        if (!selectedSimulation) return;

        setLoading(true);
        SimulationService.simulate(JSON.parse(selectedSimulation))
            .then((res) => {
                let bets = "";
                res.Bets.forEach((bet: any) => {
                    bets += `- ${bet.ruleName}\n`;
                });

                let verifications = "";
                res.Verifications.forEach((verification: any) => {
                    verifications += `${verification.ruleName}\n`;
                });

                setAppliedBets({
                    bets: bets,
                    verifications: verifications,
                });

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notify("Failed to run simulation", "error", TOASTER_DELAY);
                setLoading(false);
            });
    };

    const updateSimulationHandler = () => {
        let updatedSimulation: any = {};

        if (!selectedSimulation) {
            notify("Simulation data is empty", "error", TOASTER_DELAY);
            return;
        }

        try {
            updatedSimulation = JSON.parse(selectedSimulation);
        } catch (err) {
            notify("Simulation data is not a valid JSON", "error", TOASTER_DELAY);
            return;
        }

        const simulationIndex = simulations.findIndex(
            (s) => s.id === updatedSimulation.id
        );

        if (simulationIndex === -1) {
            notify("Simulation not found", "error", TOASTER_DELAY);
            return;
        }

        runSimulationHandler();

        // const updatedSimulations = [...simulations];

        // updatedSimulations[simulationIndex] = updatedSimulation;

        // setLoading(true);
        // SimulationService.updateMatchSimulator(updatedSimulations)
        //     .then((res) => {
        //         getSimulations();
        //         setIsEditMode(false);
        //         setLoading(false);
        //         notify("Simulation data updated", "success", TOASTER_DELAY);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         notify("Failed to update simulation data", "error", TOASTER_DELAY);
        //         setLoading(false);
        //     });
    };

    useEffect(() => {
        getSimulations();
    }, []);

    return (
        <div>
            <h1>Simulation</h1>
            <div>Manage, run and edit simulations here.</div>

            <div className="simulation-container">
                <div className="form-container">
                    <label className="form-label">
                        Select Match:
                    </label>
                    <SelectBox
                        valueExpr="id"
                        displayExpr={(item: any) => {
                            if (!item) return "";

                            return `${item?.homeName} Vs ${item?.awayName}`;
                        }}
                        searchEnabled={true}
                        placeholder="Select a match"
                        dataSource={simulations}
                        onValueChanged={(e) => {
                            selectedSimulationHandler(e.value);
                        }}
                        style={{
                            width: "100%",
                        }}
                    />

                    <label className="form-label">
                        Simulation Data:
                    </label>
                    <ReactJson
                        src={JSON.parse(selectedSimulation)}
                        name={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                        collapsed={true}
                        {...(isEditMode && {
                            onEdit: (e) => {
                                if (!isEditMode) return;

                                setSelectedSimulation(JSON.stringify(e.updated_src));
                            },
                            onAdd: (e) => {
                                if (!isEditMode) return;

                                setSelectedSimulation(JSON.stringify(e.updated_src));
                            },
                            onDelete: (e) => {
                                if (!isEditMode) return;

                                setSelectedSimulation(JSON.stringify(e.updated_src));
                            },
                        })}
                        validationMessage="Invalid JSON"
                        style={{
                            fontFamily: "monospace",
                            whiteSpace: "pre",
                            overflowX: "scroll",
                            backgroundColor: !isEditMode ? "#eee" : "#fff",
                            border: !isEditMode ? "1px dashed #ccc" : "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "5px",
                        }}
                    />

                    <div className="action-container">
                        {!isEditMode && (
                            <div>
                                <Button
                                    text="Run Simulation"
                                    type="default"
                                    className="run-simulator"
                                    stylingMode="contained"
                                    disabled={
                                        !selectedSimulation ||
                                        selectedSimulation === "{}" ||
                                        loading
                                    }
                                    onClick={() => runSimulationHandler()}
                                />
                                <Button
                                    text="Edit Simulation"
                                    type="default"
                                    stylingMode="contained"
                                    disabled={
                                        !selectedSimulation ||
                                        selectedSimulation === "{}" ||
                                        loading
                                    }
                                    onClick={() => setIsEditMode(true)}
                                />
                            </div>
                        )}

                        {isEditMode && (
                            <div>
                                <Button
                                    text="Run Simulation"
                                    type="default"
                                    stylingMode="contained"
                                    className="update-simulator"
                                    disabled={!isEditMode || loading}
                                    onClick={() => updateSimulationHandler()}
                                />
                                <Button
                                    text="Cancel Edit"
                                    type="danger"
                                    stylingMode="contained"
                                    disabled={!isEditMode || loading}
                                    onClick={() => {
                                        setIsEditMode(false);
                                        selectedSimulationHandler(
                                            JSON.parse(selectedSimulation).id
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="bets-container">
                    <label className="form-label">
                        Bets:
                    </label>
                    <TextArea
                        value={appliedBets?.bets || ""}
                        readOnly={true}
                        height={250}
                        className="bets-textarea"
                    />

                    <label className="form-label">
                        Verifications:
                    </label>
                    <TextArea
                        value={appliedBets?.verifications || ""}
                        readOnly={true}
                        height={250}
                        className="bets-textarea"
                    />
                </div>
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: "#simulations"}}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>
    );
};

export default Simulations;

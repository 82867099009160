import Drawer from "devextreme-react/drawer";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Header, Main, Sidebar } from "./Components";
import AuthService from "./Services/Auth/AuthService";
import "./App.scss";
interface Props {}

const ProtectedRoute = () => {
  const navigation = useNavigate();
  const [drawerState, setDrawerState] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);

  return AuthService.isAuthenticated() ? (
    <div className="main-layout-dashboard">
      <Drawer
        opened={showSidebar}
        openedStateMode={"shrink"}
        className={"main-container"}
        revealMode="slide"
        height={"100%"}
        component={() => <Sidebar />}
      >
        <Header handleShowSidebar={setShowSidebar} showSidebar={showSidebar} />
        <Main />
      </Drawer>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;

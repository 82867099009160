import {TopupReportPayloadDTO} from '../../Models/TopupReport/TopupReport';
import AccountingBaseService from '../AccountingBaseService';
import FactoryService from '../FactoryService';
import ReportingBaseService from "../ReportingBaseService";

const factoryService = new FactoryService();

class TopupReport {
    private TOPUP_ENDPOINT = 'api/Transactions/pagedTopupReport';
    private TOPUP_EXPORT_ENDPOINT = 'api/Transactions/pagedTopupReport/export';
    private TOPUP_STATUS_ENDPOINT = 'api/Transactions/statuses';
    private BALANCE_ENDPOINT = "api/Credits";

    async getReport(payload: TopupReportPayloadDTO) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.TOPUP_ENDPOINT}`, payload, true);
        if (result) {
            return result;
        }
        return null;
    }

    async getTopupStatuses() {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.TOPUP_STATUS_ENDPOINT}`, true);
        if (result) {
            return result;
        }
        return null;
    }

    async exportReport(payload: TopupReportPayloadDTO) {
        const data = {
            ...payload,
            pageSize: 99999,
        };
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.TOPUP_EXPORT_ENDPOINT}`, data, true);
        if (result) {
            return result;
        }
        return null;
    }


    async getBalance(externalReference: string) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.BALANCE_ENDPOINT}/${externalReference}/balance`, true);

        if (result) {
            return result;
        }

        return null;
    }
}

export default new TopupReport();

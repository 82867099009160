import React from "react";
import {LoadPanel} from "devextreme-react/load-panel";

import {LOADER_BACKGROUND} from "../../Constants/values";

type Props = {
    isLoaderVisible: boolean;
}
const Loader = ({isLoaderVisible}: Props) => {
    return <LoadPanel
        shadingColor={LOADER_BACKGROUND}
        position={'center'}
        visible={isLoaderVisible}
        showIndicator={true}
        shading={true}
        id={'usersLoader'}
        showPane={true}
    />
}

export default Loader;
import {Button, DateBox, TagBox, Validator} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import Loader from '../../../../Components/Loader';
import {COUNTRY_CODES} from '../../../../Constants/values';
import checkValidation from '../../../../Utils/FormValidation';
import '../CustomerReport.scss';
import TextBox from "devextreme-react/text-box";
import {CustomerReportPayloadDTO} from "../../../../Models/Reports/CustomerReport";
import SelectBox from "devextreme-react/select-box";
import React from "react";

type Props = {
    customerReportPayload: CustomerReportPayloadDTO;
    setCustomerReportPayload: React.Dispatch<CustomerReportPayloadDTO>;
    loading: boolean;
    handleFilterCustomerReport: () => void;
};

const CustomerReportFilter = ({
                                  customerReportPayload,
                                  setCustomerReportPayload,
                                  loading,
                                  handleFilterCustomerReport,
                              }: Props) => {

    return (
        <article className="topup__filter">
            <div className="topup__filter-fields">

                <TagBox
                    items={COUNTRY_CODES}
                    width={'100%'}
                    displayExpr={'name'}
                    valueExpr={'name'}
                    inputAttr={{
                        id: 'BillingStatus',
                        name: 'BillingStatus',
                    }}
                    placeholder="Select Tax country"
                    searchEnabled
                    hideSelectedItems
                    className="topup__client-selectBox"
                    onValueChange={(e) => {
                        setCustomerReportPayload({
                            ...customerReportPayload,
                            taxCountries: e,
                        });
                    }}
                />


                <DateBox
                    id="topup__startDate"
                    placeholder="Date from"
                    inputAttr={{
                        id: 'transactions-startDate',
                    }}
                    // displayFormat="dd/MM/yyyy"
                    value={customerReportPayload.startDate ?? undefined}
                    onValueChanged={(e) => {
                        const payload: CustomerReportPayloadDTO = {
                            ...customerReportPayload,
                            startDate: e.value,
                        };
                        setCustomerReportPayload(payload);
                    }}
                >
                    <Validator validationGroup="TopupValidator">
                        <RequiredRule message="Start Date is required"/>
                    </Validator>
                </DateBox>
                <DateBox
                    id="topup__endDate"
                    placeholder="Date to"
                    inputAttr={{
                        id: 'transactions-endDate',
                    }}
                    value={customerReportPayload.endDate ?? undefined}
                    onValueChanged={(e) => {
                        const payload: CustomerReportPayloadDTO = {
                            ...customerReportPayload,
                            endDate: e.value,
                        };
                        setCustomerReportPayload(payload);
                    }}
                >
                    <Validator validationGroup="TopupValidator">
                        <RequiredRule message="End Date is required"/>
                    </Validator>
                </DateBox>

                <SelectBox
                    dataSource={[
                        {id: "true", name: "Bot Access"},
                        {id: "false", name: "No Bot Access"},
                        {id: "", name: "All"},
                    ]}
                    displayExpr="name"
                    valueExpr="id"
                    value={customerReportPayload.botAccess}
                    placeholder="Bot Access"
                    onValueChanged={(e) => {
                        const payload: CustomerReportPayloadDTO = {
                            ...customerReportPayload,
                            botAccess: e.value,
                        };

                        setCustomerReportPayload(payload);
                    }}
                    style={{
                        marginLeft: "10px",
                    }}
                />
            </div>

            <Button
                style={{marginTop: '15px'}}
                text="Search"
                type="default"
                id="transactionsSearch"
                useSubmitBehavior={true}
                validationGroup="TopupValidator"
                stylingMode="contained"
                disabled={
                    !customerReportPayload.startDate && !customerReportPayload.endDate
                }
                onClick={(e) => checkValidation(e, handleFilterCustomerReport)}
            />

            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
                backgroundColor: '#f5f5f5',
                padding: '10px',
            }}>
                <TextBox
                    value={customerReportPayload.keyword}
                    placeholder={'Search by user'}
                    onValueChanged={(e) => {
                        const searchKeyVal = e.value;
                        setCustomerReportPayload({
                            ...customerReportPayload,
                            keyword: searchKeyVal,
                        });
                    }}
                />
            </div>
            <Loader isLoaderVisible={loading}/>
        </article>
    );
};

export default CustomerReportFilter;

import FactoryService from "../FactoryService";
import TipBaseService from "../TipBaseService";

const factoryService = new FactoryService();

class DashboardService {
    private DASHBOARD_ENDPOINT = "FootballMatches/chartdata";
    private REPORT_ENDPOINT = "FootballMatches/report";

    async getDashboard(dashboardFilters: any) {

        let queryString = "";
        if (dashboardFilters) {
            queryString = "?";
            for (let key in dashboardFilters) {
                if (dashboardFilters[key] && dashboardFilters[key] !== "")
                    queryString += `${key}=${dashboardFilters[key]}&`;
            }
        }

        queryString = queryString.slice(0, -1);

        const response = await (
            await factoryService.create(TipBaseService)
        ).getAsJson(`${this.DASHBOARD_ENDPOINT}/${queryString}`, true);
        return response;
    }

    async getReport(dashboardFilters: any, pageNumber: number) {

        let queryString = "";
        if (dashboardFilters) {
            queryString = "?";
            for (let key in dashboardFilters) {
                if (dashboardFilters[key] && dashboardFilters[key] !== "")
                    queryString += `${key}=${dashboardFilters[key]}&`;
            }
        }

        queryString = queryString.slice(0, -1);

        const response = await (
            await factoryService.create(TipBaseService)
        ).getAsJson(`${this.REPORT_ENDPOINT}/${queryString}&PageNumber=${pageNumber}`, true);
        return response;
    }

}

export default new DashboardService();

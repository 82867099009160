import Button from "devextreme-react/button";
import Popup from 'devextreme-react/popup';
import checkValidation from "../../../Utils/FormValidation";
import {COUNTRY_CODES, TOASTER_DELAY} from "../../../Constants/values";
import notify from "devextreme/ui/notify";
import {SelectBox, Validator} from "devextreme-react";
import BlackoutService from "../../../Services/Blackout/BlackoutService";
import {PatternRule, RequiredRule} from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";

type BlackoutProps = {
    startTime: any;
    endTime: any;
    countryIso2?: string;
}

type Props = {
    isAddPopupVisible: boolean;
    addPopupVisibilityHandler: any;
    loadingHandler: (value: boolean) => void;
    fetchBlackoutList: () => void;
    blackoutDetails: BlackoutProps;
    blackoutDetailsHandler: (value: BlackoutProps) => void;
}
const AddBlackoutPopup = ({
                              isAddPopupVisible,
                              addPopupVisibilityHandler,
                              loadingHandler,
                              fetchBlackoutList,
                              blackoutDetails,
                              blackoutDetailsHandler
                          }: Props) => {

    const onSubmit = () => {
        loadingHandler(true);

        const payload = {
            startTime: blackoutDetails.startTime,
            endTime: blackoutDetails.endTime,
            countryIso2: blackoutDetails.countryIso2
        }

        BlackoutService.AddBlackout(payload)
            .then(() => {
                blackoutDetailsHandler({
                    startTime: "",
                    endTime: "",
                    countryIso2: "",
                });
                fetchBlackoutList();
                loadingHandler(false);
                addPopupVisibilityHandler();
                notify("Blackout defaults added successfully", "success", TOASTER_DELAY);

            })
            .catch((e) => {
                notify("Error while adding blackout defaults", "error", TOASTER_DELAY);
                loadingHandler(false);
                addPopupVisibilityHandler();
            });
    };

    return (
        <Popup
            width={660}
            height={540}
            showTitle={true}
            title="Add Bladkout Default"
            dragEnabled={false}
            visible={isAddPopupVisible}
            onHiding={addPopupVisibilityHandler}>
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Start Time (i.e. 12:00:00) GMT"
                        value={blackoutDetails.startTime}
                        onValueChange={(e) => blackoutDetailsHandler({
                                ...blackoutDetails,
                                startTime: e
                            }
                        )}
                    >
                        <Validator>
                            <RequiredRule message="Start Time is required"/>
                            <PatternRule
                                message="Hour entered is incorrect (i.e. 12:08:00)"
                                pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="End Time (i.e. 14:00:00) GMT"
                        value={blackoutDetails.endTime}
                        onValueChange={(e) => blackoutDetailsHandler({
                                ...blackoutDetails,
                                endTime: e
                            }
                        )}
                    >
                        <Validator>
                            <RequiredRule message="End Time is required"/>
                            <PatternRule
                                message="Hour entered is incorrect (i.e. 12:08:00)"
                                pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <SelectBox
                        dataSource={COUNTRY_CODES}
                        displayExpr={"name"}
                        value={blackoutDetails.countryIso2}
                        valueExpr={"code"}
                        searchEnabled={true}
                        onValueChanged={(e) => {
                            const chosenCountry = e.value;
                            blackoutDetailsHandler(
                                {
                                    ...blackoutDetails,
                                    countryIso2: chosenCountry
                                }
                            )
                        }}
                        placeholder="Country"
                    >
                        <Validator>
                            <RequiredRule message="Country is required"/>
                        </Validator>
                    </SelectBox>
                </div>
                <div className="textboxContainer">
                    <Button
                        text="Add Blackout"
                        type="success"
                        className="addBlackout"
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}/>
                </div>
            </div>
        </Popup>
    )
};


export default AddBlackoutPopup;
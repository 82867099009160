import './HamburgerButton.scss';
type Props = {
  onClick: () => void;
  isOpened?: boolean;
};

export function HamburgerButton({ onClick, isOpened }: Props) {
  return (
    <button
      className={[
        'hamburger-button',
        isOpened ? 'hamburger-button--open' : '',
      ].join(' ')}
      onClick={onClick}
    >
      <span className="hamburger-button__line hamburger-button__line--1"></span>
      <span className="hamburger-button__line hamburger-button__line--2"></span>
      <span className="hamburger-button__line hamburger-button__line--3"></span>
    </button>
  );
}

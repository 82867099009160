import "./AddRole.scss";

import Button from "devextreme-react/button";
import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import Popup from 'devextreme-react/popup';
import Validator, { EmailRule, RequiredRule } from "devextreme-react/validator";

import RolesService from "../../Services/Roles/Roles";
import checkValidation from "../../Utils/FormValidation";

function AddRolePopup(
    showAddEditPanel:boolean,     
    handlePopupHidden : () => void, 
    addUserDone: (message:string, isError:boolean) => void,  

    roleName:string, 
    setRoleName:(value:string)=>void)
    {

      const onSubmit = () => {      
        const data: string = roleName;  
        
         RolesService.AddRole(data)
         .then((r)=>{
             
          setRoleName("");
          handlePopupHidden();
  
          addUserDone("Successfuly added", false);
  
         })
        .catch((e)=>
        {
          addUserDone("Failed adding: " + e, true);          
        })
        ;
      };


    return (
        <Popup
          width={660}
          height={540}
          showTitle={true}
          title="Add Role"
          dragEnabled={false}
          closeOnOutsideClick={true}
          visible={showAddEditPanel}
          onHiding={handlePopupHidden}>
            <div>
              <div className="textboxContainer">
                <TextBox
                  placeholder="Role Name"
                  value={roleName}
                  onValueChange={(e) => setRoleName(e)} >
                  <Validator>
                    <RequiredRule message="Role name is required" />
                  </Validator>
                </TextBox>
              </div>              
              <div className="textboxContainer">
                <Button
                  text="Add Role"
                  type="success"
                  className="addUser"
                  useSubmitBehavior={true}
                  onClick={(e) => checkValidation(e, onSubmit)} />
              </div>
            </div>
        </Popup>
        )
    };


export default AddRolePopup;
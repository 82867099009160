import {useLocation} from "react-router";
import React, {useEffect, useState} from "react";

import {ROUTES} from "../../routes";
import notify from "devextreme/ui/notify";
import {Grid} from "../../Components/Grid/Grid";
import {PaginationResponseDto} from "../../Models/PaginationResponseDto";
import {Column} from "devextreme-react/data-grid";
import ButtonBox from "devextreme-react/button";
import ManualCredit from "./ManualCredit/ManualCredit";
import Loader from "../../Components/Loader";
import ReportingService from "../../Services/Reporting/ReportingService";
import TextBox from "devextreme-react/text-box";

import SelectBox from "devextreme-react/select-box";
import TopupReport from "../../Services/TopupReport/TopupReport";

import "./Styles.scss"

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

const Transactions = () => {
    const {state} = useLocation();
    const [transactions, setTransactions] = useState([]);
    const [paginationData, setPaginationData] = useState<PaginationResponseDto>({
        totalPages: 0,
        pageNumber: 1,
    });
    const [showManualCreditPopup, setShowManualCreditPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>(state?.externalReference || "");
    const [balance, setBalance] = useState(0);

    const getTransactions = (pageNumber: number = 1) => {
        if (!selectedUser) {
            return;
        }

        ReportingService.getTransactions(selectedUser, pageNumber, searchText).then((res) => {
            setTransactions(res?.result);

            setPaginationData({
                ...paginationData,
                totalRecords: res?.totalItemCount,
                totalPages: res?.totalPages
            })
        }).catch((e) => {
            notify("Error getting transactions", "error", 3000);
        });
    }

    const getBalanceHandler = () => {
        if (!selectedUser) {
            return;
        }

        TopupReport.getBalance(selectedUser).then((res) => {
            setBalance(res);
        }).catch((e) => {
            notify("Error getting balance", "error", 3000);
        });
    }


    const gridColumns = [
        <Column
            key="transactionId"
            dataField={'transactionId'}
            alignment={'left'}
            caption={'ID'}
            width={110}
        />,
        <Column
            key="currencyIso3"
            dataField={'currencyIso3'}
            alignment={'left'}
            caption={'Currency'}
        />,
        <Column
            key="signedAmount"
            dataField={'signedAmount'}
            alignment={'left'}
            caption={'Signed Amount'}
        />,
        <Column
            key="createdOn"
            dataField={'createdOn'}
            alignment={'left'}
            caption={'Created On'}
            cellRender={(cellData) => {
                return (
                    <>{new Date(cellData.data.createdOn).toLocaleDateString('en-GB')}</>
                );
            }}
        />,
        <Column
            key="transactionStatusName"
            dataField={'transactionStatusName'}
            alignment={'left'}
            caption={'Status'}
        />,
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            <Column
                key="signedCredit"
                dataField={'signedCredit'}
                alignment={'left'}
                caption={'Signed Credit'}
            />,
            <Column
                key="creditRate"
                dataField={'creditRate'}
                alignment={'left'}
                caption={'Credit Rate'}
                cellRender={(cellData) => {
                    return (
                        <>{cellData.data.creditRate ? cellData.data.creditRate : "N/A"}</>
                    );
                }}
            />,
        ] : [],
        <Column
            key="transactionNote"
            dataField={'transactionNote'}
            alignment={'left'}
            width="auto"
            caption={'Note'}
        />,
    ]

    useEffect(() => {
        if (!state) {
            window.location.href = ROUTES.USERS;
        }

        getTransactions(paginationData.pageNumber);
    }, []);

    useEffect(() => {
        if (selectedUser) {
            getBalanceHandler();
            getTransactions(paginationData.pageNumber);
        }
    }, [selectedUser]);

    return (
        <div>
            <h1>Transactions</h1>
            <div>Manage the customers in your organisations from here.</div>

            <div className="transaction-filter-container">
                <div className="filter-container">
                    <SelectBox
                        dataSource={state?.transactionUserList}
                        displayExpr={(item: any) => {
                            if (!item) return "";

                            if (!item.name || !item.familyName) {
                                return item?.phoneNumber;
                            }

                            return `${item?.phoneNumber} (${item?.name} ${item?.familyName})`;
                        }}
                        valueExpr="userExternalReference"
                        value={selectedUser}
                        onValueChanged={(e) => {
                            setSelectedUser(e.value);
                        }}
                        placeholder="Select User"
                    />

                    {
                        REACT_APP_PAYMENT_MODEL === "CREDIT" &&
                        <div className="balance-container">
                            <p>
                                Balance: <strong>{balance || 0}</strong>
                            </p>
                            <ButtonBox
                                icon={"add"}
                                text={"Add Credit"}
                                onClick={() => setShowManualCreditPopup(true)}
                            ></ButtonBox>
                        </div>
                    }


                </div>

                <div className="transaction-search">
                    <TextBox
                        placeholder="Search"
                        className="search-field"
                        showClearButton={true}
                        value={searchText}
                        onValueChanged={(e) => setSearchText(e.value)}
                        stylingMode="outlined"
                    />
                    <ButtonBox
                        text="Search"
                        icon={"search"}
                        onClick={() => getTransactions(paginationData.pageNumber)}
                    ></ButtonBox>
                </div>
            </div>
            <Grid
                Data={transactions}
                keyExpr="transactionId"
                customColumns={gridColumns}
                PaginationData={paginationData}
                gotoPageCallback={(pageNumber) =>
                    getTransactions(pageNumber)
                }
            />

            <ManualCredit
                setLoading={setIsLoading}
                fetchTransactions={getTransactions}
                fetchBalance={getBalanceHandler}
                showPopUp={showManualCreditPopup}
                popUpHandler={setShowManualCreditPopup}
                externalReference={selectedUser}
            />

            <Loader isLoaderVisible={isLoading}/>
        </div>
    );
}

export default Transactions;
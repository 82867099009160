import envConfig from "../configs/env.config";
import { BaseService } from "./BaseService";
import IServiceProvider from "./IServiceProvider";

class IdentityServerBaseService extends BaseService implements IServiceProvider {  
  constructor() {
    super();
  }
  
  async initialize() {    
      this.baseUrl = envConfig.REACT_APP_IDENTITYSERVER_API_BASE_URL;    
  } 
}
export default IdentityServerBaseService;

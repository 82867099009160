import Button from "devextreme-react/button";
import Popup from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";

import checkValidation from "../../../Utils/FormValidation";

import {COUNTRY_CODES, TOASTER_DELAY} from "../../../Constants/values";

import BlackoutService from "../../../Services/Blackout/BlackoutService";
import {Validator} from "devextreme-react";
import {PatternRule, RequiredRule} from "devextreme-react/validator";
import {useEffect} from "react";
import TextBox from "devextreme-react/text-box";

type BlackoutProps = {
    startTime: any;
    endTime: any;
    countryIso2?: string;
}

type Props = {
    blackoutID: string;
    isEditPopupVisible: boolean;
    editPopupVisibilityHandler: any;
    loadingHandler: (value: boolean) => void;
    fetchBlackoutList: () => void;
    blackoutDetails: BlackoutProps;
    blackoutDetailsHandler: (value: BlackoutProps) => void;
}
const EditBlackoutPopup = ({
                               blackoutID,
                               isEditPopupVisible,
                               editPopupVisibilityHandler,
                               loadingHandler,
                               fetchBlackoutList,
                               blackoutDetails,
                               blackoutDetailsHandler
                           }: Props) => {

    const onSubmit = () => {
        const payload = {
            startTime: blackoutDetails.startTime,
            endTime: blackoutDetails.endTime,
        }

        loadingHandler(true);
        BlackoutService.UpdateBlackout(payload, blackoutID)
            .then(() => {
                fetchBlackoutList();
                loadingHandler(false);
                editPopupVisibilityHandler();
                notify("Blackout default updated successfully", "success", TOASTER_DELAY);

            })
            .catch((e) => {
                notify("Error while updating blackout default", "error", TOASTER_DELAY);
                loadingHandler(false);
                editPopupVisibilityHandler();
            });
    };

    return (
        <Popup
            width={660}
            height={540}
            showTitle={true}
            title="Edit Blackout Default"
            dragEnabled={false}
            visible={isEditPopupVisible}
            onHiding={editPopupVisibilityHandler}>
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Start Time (i.e. 12:00:00) GMT"
                        value={blackoutDetails.startTime}
                        onValueChange={(e) => blackoutDetailsHandler({
                                ...blackoutDetails,
                                startTime: e
                            }
                        )}
                    >
                        <Validator>
                            <RequiredRule message="Start Time is required"/>
                            <PatternRule
                                message="Hour entered is incorrect (i.e. 12:08:00)"
                                pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="End Time (i.e. 14:00:00) GMT"
                        value={blackoutDetails.endTime}
                        onValueChange={(e) => blackoutDetailsHandler({
                                ...blackoutDetails,
                                endTime: e
                            }
                        )}
                    >
                        <Validator>
                            <RequiredRule message="End Time is required"/>
                            <PatternRule
                                message="Hour entered is incorrect (i.e. 12:08:00)"
                                pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <Button
                        text={"Edit Blackout"}
                        type="success"
                        className="addBlackout"
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}/>
                </div>
            </div>
        </Popup>
    )
};


export default EditBlackoutPopup;
import FactoryService from "../FactoryService";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class CreditService {
    private CREDIT_ENDPOINT = "api/CreditRates";
    private TRANSACTION_ENDPOINT = "api/Transactions";
    private MANUAL_CREDIT_ENDPOINT = "api/Credits/manual";
    private PAYMENT_METHOD_ENDPOINT = "api/PaymentMethods/all";
    private BULK_CREDIT_ENDPOINT = "api/Credits/bulk";
    private RESET_CACHE = "api/Credits/cache/reset";

    async getCredit(payload: { PageNumber: number, PageSize: number }) {

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.CREDIT_ENDPOINT}/?PageNumber=${payload.PageNumber}&PageSize=${payload.PageSize}`, true);
        if (result) {
            return result;
        }
        return null;
    }

    async addCredit(payload: any) {

        const data = {
            rate: Number(payload.rate),
            currencyIso3: payload.currencyIso3,
            countryIso2: payload.countryIso2,
            paymentMethodIds: payload.paymentMethodIds
        }

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.CREDIT_ENDPOINT}`, data, true);
        if (result) {
            return result;
        }
        return null;
    }

    async updateCredit(payload: any) {

        const data = {
            rate: Number(payload.rate),
            currencyIso3: payload.currencyIso3,
            default: payload.default ? payload.default : false,
            ...(!payload.default ? {countryIso2: payload.countryIso2} : {}),
            paymentMethodIds: payload.paymentMethodIds
        }

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).putAsJson(`${this.CREDIT_ENDPOINT}/${payload.id}`, data, true);
        if (result) {
            return result;
        }
        return null;
    }

    async deleteCredit(id: string) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).delete(`${this.CREDIT_ENDPOINT}/${id}`, true);
        if (result) {
            return result;
        }
        return null;
    }

    async getTransactions(externalReference: string, pageNumber: number) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.TRANSACTION_ENDPOINT}/${externalReference}/customer?PageNumber=${pageNumber}`, true);
        if (result) {
            return result;
        }
        return null;
    }

    async addManualCredit(payload: {
        userExternalReference: string,
        credits: string,
        reference: string,
        note: string,
    }) {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.MANUAL_CREDIT_ENDPOINT}`, payload, true);
        if (result) {
            return result;
        }
        return null;
    }

    async getPaymentMethods() {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(this.PAYMENT_METHOD_ENDPOINT, true);
        if (result) {
            return result;
        }
        return null;
    }

    async bulkCredit(payload: any) {

        const data = {
            ...payload,
            credits: payload.credits ? payload.credits : "0",
        }

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.BULK_CREDIT_ENDPOINT}`, data, true);
        if (result) {
            return result;
        }

        return null;
    }

    async resetAccountingCache() {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(this.RESET_CACHE, {}, true);
        if (result) {
            return result;
        }
        return null;
    }

}

export default new CreditService();

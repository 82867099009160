import Button from "devextreme-react/button";
import TextBox from "devextreme-react/text-box";
import Popup from 'devextreme-react/popup';
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import checkValidation from "../../../Utils/FormValidation";
import CreditService from "../../../Services/Credit/CreditService";
import {COUNTRY_CODES, COUNTRY_CURRENCY, TOASTER_DELAY} from "../../../Constants/values";
import notify from "devextreme/ui/notify";
import {SelectBox, TagBox} from "devextreme-react";

type CreditRates = {
    id: string;
    rate: string;
    currencyIso3: string;
    countryIso2: string,
    paymentMethodIds: number[] | null;
    default?: boolean;
}

type Props = {
    isEditPopupVisible: boolean;
    editPopupVisibilityHandler: any;
    loadingHandler: (value: boolean) => void;
    fetchCreditList: () => void;
    creditRates: CreditRates;
    creditRatesHandler: (value: CreditRates) => void;
    paymentMethods: any[];
}
const EditCreditPopup = ({
                             isEditPopupVisible,
                             paymentMethods,
                             editPopupVisibilityHandler,
                             loadingHandler,
                             fetchCreditList,
                             creditRates,
                             creditRatesHandler
                         }: Props) => {

    const onSubmit = () => {
        loadingHandler(true);
        CreditService.updateCredit(creditRates)
            .then(() => {
                creditRatesHandler({
                    id: "",
                    rate: "",
                    currencyIso3: "",
                    countryIso2: "",
                    paymentMethodIds: []
                });
                fetchCreditList();
                loadingHandler(false);
                editPopupVisibilityHandler();
                notify("Credit rates edited successfully", "success", TOASTER_DELAY);

            })
            .catch((e) => {
                notify("Error while editing credit rates", "error", TOASTER_DELAY);
                loadingHandler(false);
                editPopupVisibilityHandler();
            });
    };

    return (
        <Popup
            width={660}
            height={540}
            showTitle={true}
            title="Edit Credit Rates"
            dragEnabled={false}
            visible={isEditPopupVisible}
            onHiding={editPopupVisibilityHandler}>
            <div>
                <div className="textboxContainer">
                    <div className="credit-popup-container">
                        <div className="credit-title">
                            <h5>1 Credit =</h5>
                        </div>
                        <TextBox
                            placeholder="Rate"
                            style={{
                                marginBottom: "10px",
                                marginRight: "10px"
                            }}
                            value={creditRates.rate}
                            onValueChange={(e) => creditRatesHandler(
                                {
                                    ...creditRates,
                                    rate: e
                                }
                            )}>
                            <Validator>
                                <RequiredRule message="Rate is required"/>
                                <PatternRule
                                    message="Rate has to be a number and can have up to 2 decimal places"
                                    pattern={/^\d+(\.\d{1,2})?$/}
                                />
                            </Validator>
                        </TextBox>

                        < SelectBox
                            dataSource={COUNTRY_CURRENCY}
                            value={creditRates.currencyIso3}
                            onValueChanged={(e) => creditRatesHandler(
                                {
                                    ...creditRates,
                                    currencyIso3: e.value
                                }
                            )}
                            style={{
                                marginBottom: "10px"
                            }}
                            placeholder="Currency"
                            searchEnabled={true}
                            searchMode="contains"
                            searchExpr="this"
                            searchTimeout={200}
                            minSearchLength={0}
                            showClearButton={true}
                        >
                            <Validator>
                                <RequiredRule message="Currency is required"/>
                            </Validator>
                        </SelectBox>
                    </div>

                    {!creditRates.default &&
                        <SelectBox
                            dataSource={COUNTRY_CODES}
                            value={creditRates.countryIso2}
                            onValueChanged={(e) => creditRatesHandler(
                                {
                                    ...creditRates,
                                    countryIso2: e.value
                                }
                            )}
                            style={{
                                marginBottom: "10px"
                            }}
                            displayExpr={"name"}
                            valueExpr={"code"}
                            placeholder="Country"
                            searchEnabled={true}
                            searchMode="contains"
                            searchExpr="name"
                            searchTimeout={200}
                            minSearchLength={0}
                            showClearButton={true}
                        >
                            <Validator>
                                <RequiredRule message="Country is required"/>
                            </Validator>
                        </SelectBox>}

                    <TagBox
                        dataSource={paymentMethods}
                        value={creditRates.paymentMethodIds || []}
                        valueExpr="id"
                        displayExpr="name"
                        placeholder="Payment Methods"
                        searchEnabled={true}
                        searchMode="contains"
                        searchExpr="name"
                        searchTimeout={200}
                        minSearchLength={0}
                        showClearButton={true}
                        onValueChanged={(e) => creditRatesHandler(
                            {
                                ...creditRates,
                                paymentMethodIds: e.value
                            }
                        )}
                    >
                        <Validator>
                            <RequiredRule message="At least one payment method is required"/>
                        </Validator>
                    </TagBox>

                </div>
                <div className="textboxContainer">
                    <Button
                        text="Edit Credit"
                        type="success"
                        className="addCredit"
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}/>
                </div>
            </div>
        </Popup>
    )
};


export default EditCreditPopup;
const checkPasswordValidation = (password: string) => {
  const result: string[] = [];
  if (password.trim().length < 6) {
    result.push("Passwords must be at least 6 characters.");
  }
  if (password.match(/^[0-9A-Za-z]+$/) !== null) {
    result.push("Passwords must have at least one non alphanumeric character.");
  }
  if (!/\d/.test(password)) {
    result.push("Passwords must have at least one digit ('0'-'9').");
  }
  if (!/[A-Z]/.test(password)) {
    result.push("Passwords must have at least one uppercase ('A'-'Z').");
  }
  return result;
};

export default checkPasswordValidation;

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Button, DateBox, LoadPanel} from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';

import Pagination from "../../Components/Grid/PaginationComponent";

import DashboardService from '../../Services/Dashboard/DashboardService';

import {TOASTER_DELAY} from '../../Constants/values';

import {Column, DataGrid, MasterDetail, SearchPanel} from "devextreme-react/data-grid";

import {PaginationResponseDto} from "../../Models/PaginationResponseDto";

import './Dashboard.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: true,
            text: 'Rule Engine Performance',
        },
    },
    scales: {
        y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            ticks: {
                stepSize: 1,
                beginAtZero: true,
            },
        },
        y1: {
            type: 'linear' as const,
            display: false,
            position: 'right' as const,
            ticks: {
                stepSize: 1,
                beginAtZero: true,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
};

const START_DATE = "2023-12-20";

const Dashboard = () => {
    const [dashboardFilters, setDashboardFilters] = useState({
        startDate: String(new Date(START_DATE).toISOString().split('T')[0]),
        endDate: String(new Date().toISOString().split('T')[0]),
    });

    const [loading, setLoading] = useState(false);

    const [dashboardData, setDashboardData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Bets',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
            },
            {
                label: 'Wins',
                data: [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                yAxisID: 'y1',
            },
        ],
    });

    const [reportData, setReportData] = useState([]);

    const [paginationData, setPaginationData] = useState<PaginationResponseDto>({
        totalPages: 0,
        pageNumber: 1,
    });

    const getGraphDataHandler = () => {
        setLoading(true);
        DashboardService.getDashboard(dashboardFilters)
            .then((res) => {
                const labels = Object.keys(res.bets) || [];
                const bets = Object.values(res.bets) || [];
                const betsWon = Object.values(res.betsWon) || [];
                const formattedLabels: string[] = labels.map((label) => {
                    return new Date(label).toLocaleDateString('en-GB', {
                        weekday: 'short',
                    });
                });

                setDashboardData({
                    labels: formattedLabels as any,
                    datasets: [
                        {
                            label: 'Bets',
                            data: bets as any,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            yAxisID: 'y',
                        },
                        {
                            label: 'Wins',
                            data: betsWon as any,
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            yAxisID: 'y1',
                        },
                    ],
                });

                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notify(
                    'Something went wrong. Please try again later.',
                    'error',
                    TOASTER_DELAY
                );
            });
    };

    const getReportDataHandler = (pageNumber: number = 1) => {
        setLoading(true);
        DashboardService.getReport(dashboardFilters, pageNumber).then((res) => {
            setReportData(res?.result)
            setPaginationData({
                ...paginationData,
                totalRecords: res?.totalItemCount,
                totalPages: res?.totalPageCount
            })
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            notify(
                'Something went wrong. Please try again later.',
                'error',
                TOASTER_DELAY
            );
        })
    }

    const getDashboardData = () => {
        getGraphDataHandler();
        getReportDataHandler(paginationData.pageNumber);
    }

    useEffect(() => {
        getDashboardData()
    }, []);

    return (
        <div>
            <div className="dashboard-filter-container">
                <DateBox
                    id="filterStartDate"
                    placeholder="Date From"
                    className="datebox-from"
                    inputAttr={{
                        id: 'Dashboard_filterStartDate',
                    }}
                    value={dashboardFilters.startDate}
                    calendarOptions={{
                        max: new Date(new Date().setDate(new Date().getDate() - 1)),
                        min: new Date(START_DATE),
                    }}
                    displayFormat="yyyy-MM-dd"
                    onValueChanged={(e) => {
                        setDashboardFilters({
                            ...dashboardFilters,
                            startDate: e.value,
                        });
                    }}
                />
                <DateBox
                    id="filterEndDate"
                    placeholder="Date To"
                    inputAttr={{
                        id: 'Dashboard_filterEndDate',
                    }}
                    value={dashboardFilters.endDate}
                    calendarOptions={{
                        max: new Date(new Date().setDate(new Date().getDate())),
                        min: new Date(START_DATE),
                    }}
                    className="datebox-to"
                    displayFormat="yyyy-MM-dd"
                    onValueChanged={(e) => {
                        setDashboardFilters({
                            ...dashboardFilters,
                            endDate: e.value,
                        });
                    }}
                />

                <Button
                    text="Filter"
                    type="default"
                    stylingMode="contained"
                    onClick={() => getDashboardData()}
                />
            </div>

            <div
                style={{
                    width: 'calc(100vw - 300px)',
                    marginBottom: '20px',
                }}
            >
                <Line options={options} data={dashboardData}/>
            </div>

            <DataGrid
                keyExpr="match"
                id="gridContainer"
                className="grid-table"
                dataSource={reportData}
                showBorders={true}
            >
                <SearchPanel visible={true}/>
                <Column dataField="match" caption="Match"/>
                <Column dataField="matchDate" caption="Date"/>
                <MasterDetail
                    enabled={true}
                    render={(rowData) => {
                        const rules = rowData?.data?.rules || [];

                        return <DataGrid
                            keyExpr="ruleName"
                            id="gridContainer"
                            className="grid-table"
                            dataSource={rules}
                            showBorders={true}
                        >
                            <Column dataField="ruleName" caption="Rule Name"/>
                            <Column dataField="sentDate" caption="Sent Date"/>
                            <Column
                                dataField="notifiedUsers"
                                caption="Notified Users"
                                cellRender={(e) => {
                                    const users = e?.data?.notifiedUsers;

                                    return users.length ? users.length : "0"
                                }}
                            />
                        </DataGrid>
                    }}
                />
            </DataGrid>

            <Pagination
                paginationData={paginationData}
                gotoPage={(pageNumber) =>
                    getReportDataHandler(pageNumber)
                }
            />


            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#dashboard'}}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>
    );
};

export default Dashboard;

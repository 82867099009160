import FactoryService from "../FactoryService";
import {ClientFilterDto, UserDto} from "../../Models/Users/UserDto";
import {ResetPasswordDto} from "../../Models/Users/ResetPasswordDto";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class UsersService {

    private USERS_RESET_PASSWORD = "useraccount/resetpassword";
    private CUSTOMERS_ENDPOINT = "Customers";
    private UPLOAD_USER_LIST = "Customers/upload/username"

    async AddUser(user: UserDto) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.CUSTOMERS_ENDPOINT, user, true);
        if (result) {
            return result;
        }
    }

    async DeleteUser(email: string) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).delete(this.CUSTOMERS_ENDPOINT + "/" + email, true);
        if (result) {
            return result;
        }
        return null;
    }

    async UpdateUser(user: UserDto) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).putAsJson(this.CUSTOMERS_ENDPOINT, user, true);
        if (result) {
            return result;
        }
        return null;
    }

    async ToggleUserStatus(email: string, status: boolean) {

        const payload = {
            enabled: status,
            ...(!status && {lockedMonthsOut: 60})
        };

        const result = await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(`${this.CUSTOMERS_ENDPOINT}/${email}/lockedout`, payload, true);

        if (result) {
            return result;
        }
        return null;
    }

    async ResetPassword(email: string, newPassword: ResetPasswordDto) {
        const url = `${this.USERS_RESET_PASSWORD}/${email}`;
        const result = await (
            await factoryService.create(AuthBaseService)
        ).putAsJson(url, newPassword, true);
        if (result) {
            return result;
        }
        return null;
    }

    async ClientFilter(payload: any, pageNumber: number = 1) {
        let queryString = "&";

        for (const key in payload) {
            if (payload[key] && payload[key].length > 0) {
                if (key === "botAccess" || key === "keyword") {
                    queryString += `${key}=${payload[key]}&`;
                    continue;
                }

                for (const value of payload[key]) {
                    queryString += `${key}=${value}&`;
                }
            }
        }

        queryString = queryString.slice(0, -1);

        const result = await (
            await factoryService.create(AuthBaseService)
        ).getAsJson(`${this.CUSTOMERS_ENDPOINT}?PageNumber=${pageNumber}${queryString}`, true);
        if (result) {
            return result;
        }
    }

    async ExportClientFilter(payload: any) {
        let queryString = "?";

        for (const key in payload) {
            if (payload[key] && payload[key].length > 0) {

                if (key === "botAccess" || key === "keyword") {
                    queryString += `${key}=${payload[key]}&`;
                    continue;
                }

                for (const value of payload[key]) {
                    queryString += `${key}=${value}&`;
                }
            }
        }

        queryString = queryString.slice(0, -1);

        const result = await (
            await factoryService.create(AuthBaseService)
        ).getAsJson(`${this.CUSTOMERS_ENDPOINT}/export${queryString}`, true);
        if (result) {
            return result;
        }
    }

    async UploadUsersList(file: any) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.UPLOAD_USER_LIST, file, true, true);
        if (result) {
            return result;
        }
    }
}

export default new UsersService();

import {useEffect, useState} from 'react';
import {Heading} from '../../../Components/Heading/Heading';
import CustomerReportExportBtn from './CustomerReportExport/CustomerReportExportBtn';
import CustomerReportFilter from './CustomerReportFilter/CustomerReportFilter';
import CustomerReportGrid from './CustomerReportGrid/CustomerReportGrid';
import './CustomerReport.scss';
import {PaginationResponseDto} from "../../../Models/PaginationResponseDto";
import {CustomerReportPayloadDTO} from "../../../Models/Reports/CustomerReport";
import ReportingService from "../../../Services/Reporting/ReportingService";

type Props = {};

export const CustomerReport = (props: Props) => {
    const [customerReportData, setCustomerReportData] = useState<any>([]);
    const [Loading, setLoading] = useState(false);

    const [paginationData, setPaginationData] = useState<PaginationResponseDto>({
        totalPages: 0,
        pageNumber: 1,
    });

    const [customerReportPayload, setCustomerReportPayload] = useState<CustomerReportPayloadDTO>({
        taxCountries: [],
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
        endDate: new Date().toISOString(),
        pageSize: 10,
        keyword: '',
        botAccess: ''
    });

    const handleFilterCustomerReport = (pageNumber: number = 1) => {
        setLoading(true);

        ReportingService.getCustomerReport(customerReportPayload, pageNumber)
            .then((response) => {
                setCustomerReportData(response.result);
                setPaginationData({
                    ...paginationData,
                    totalRecords: response?.totalItemCount,
                    totalPages: response?.totalPages
                })
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        handleFilterCustomerReport();
    }, [customerReportPayload.keyword]);


    return (
        <>
            <section className="topup">
                <div
                    style={{
                        display: 'flex',
                        gap: '1.5rem',
                        alignItems: 'center',
                        margin: '1rem 0 1.5rem',
                    }}
                >
                    <Heading
                        level={2}
                        textContent="Customers Report"
                        style={{margin: '0'}}
                    />
                    {customerReportData.length > 0 && (
                        <CustomerReportExportBtn customerReportPayload={customerReportPayload}/>
                    )}
                </div>

                <CustomerReportFilter
                    customerReportPayload={customerReportPayload}
                    setCustomerReportPayload={setCustomerReportPayload}
                    loading={Loading}
                    handleFilterCustomerReport={handleFilterCustomerReport}
                />

                <CustomerReportGrid
                    customerReportData={customerReportData}
                    paginationData={paginationData}
                    handleFilterCustomerReport={handleFilterCustomerReport}
                />
            </section>
        </>
    );
};

import Button from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, {
    PatternRule,
    RequiredRule,
} from 'devextreme-react/validator';
import notify from "devextreme/ui/notify";

import UserDto from '../../Models/Users/UserDto';
import BackendUsersService from "../../Services/BackendUsers/BackendUsersService";

import checkValidation from '../../Utils/FormValidation';

import {TOASTER_DELAY} from "../../Constants/values";

import './EditBackendUser.scss';

type EditBackendUserPopupProps = {
    showEditPanel: boolean;
    closePopUpHandler: (value: boolean) => void;
    getBackendUsers: () => void;
    roleLookupData: string[];
    backendInformation: UserDto;
    setBackendUserInformation: (value: UserDto) => void;
    password: string;
    setPassword: (value: string) => void;
}

const EditBackendUserPopup = (
    {
        showEditPanel,
        closePopUpHandler,
        backendInformation,
        setBackendUserInformation,
        roleLookupData,
        password,
        setPassword,
        getBackendUsers
    }: EditBackendUserPopupProps) => {
    const handleCheck = (event: any) => {
        const cleanRoles = backendInformation.Roles ?? [];

        if (event.target.checked) {
            setBackendUserInformation({...backendInformation, Roles: [...cleanRoles, event.target.value]});
        } else {
            setBackendUserInformation({
                ...backendInformation,
                Roles: cleanRoles.filter((x) => x !== event.target.value)
            });
        }
    };

    const handleClientCheck = (event: any) => {
        const cleanClients = backendInformation.Clients ?? [];

        if (event.target.checked) {
            setBackendUserInformation({...backendInformation, Clients: [...cleanClients, event.target.value]});
        } else {
            setBackendUserInformation({
                ...backendInformation,
                Clients: cleanClients.filter((x) => x !== event.target.value)
            });
        }
    };
    const onSubmit = () => {
        const data: UserDto = {
            Email: backendInformation.Email,
            Name: backendInformation.Name,
            FamilyName: backendInformation.FamilyName,
            Roles: backendInformation.Roles ?? [],
            Clients: backendInformation.Clients ?? [],
            UserName: backendInformation.Email,
            Password: password,
        };

        BackendUsersService.UpdateBackendUser(data)
            .then((r) => {
                setBackendUserInformation({
                    ...backendInformation,
                    Email: "",
                    Name: "",
                    FamilyName: "",
                    Roles: [],
                    Clients: [],
                });

                setPassword('');
                closePopUpHandler(false);
                getBackendUsers()
                setBackendUserInformation({
                    ...backendInformation,
                    Roles: [],
                });
                notify("Backend user updated successfully", "success", TOASTER_DELAY);
            })
            .catch((e) => {
                closePopUpHandler(false);
                setBackendUserInformation({
                    ...backendInformation,
                    Roles: [],
                });
                notify('Failed updating: ' + e, "error", TOASTER_DELAY);
            });

    };

    const isChecked = (name: string): boolean => {
        const roleList = backendInformation.Roles ?? [];
        return roleList.includes(name);
    }

    const isClientChecked = (name: string): boolean => {
        const clientList = backendInformation.Clients ?? [];
        return clientList.includes(name);
    }

    return (
        <>
            <Popup
                width={660}
                height={540}
                showTitle={true}
                title="Edit Backend User"
                dragEnabled={false}
                visible={showEditPanel}
                onHiding={() => closePopUpHandler(false)}
            >
                <div>
                    <div className="textboxContainer">
                        <TextBox
                            id="editEmailAddress"
                            placeholder="Email Address"
                            value={backendInformation.Email}
                            onValueChange={(e) => setBackendUserInformation({...backendInformation, Email: e})}
                        >
                            <Validator>
                                <RequiredRule message="Email Address is required"/>
                                <PatternRule
                                    message="Email entered is incorrect"
                                    pattern={/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g}
                                />{' '}
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="textboxContainer">
                        <TextBox
                            id="editFirstName"
                            placeholder="First name"
                            value={backendInformation.Name}
                            onValueChange={(e) => setBackendUserInformation({...backendInformation, Name: e})}
                        >
                            <Validator>
                                <RequiredRule message="First name is required"/>
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="textboxContainer">
                        <TextBox
                            id="editLastName"
                            placeholder="Last name"
                            value={backendInformation.FamilyName}
                            onValueChange={(e) => setBackendUserInformation({...backendInformation, FamilyName: e})}
                        >
                            <Validator>
                                <RequiredRule message="Last name is required"/>
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="lefttextboxContainer">
                        <fieldset className="user__fieldset">
                            <legend>Roles:</legend>
                            <ul className="user__roles">
                                {roleLookupData.map((item, index) => {
                                    return (
                                        <li className="user__role" key={item + index + 1}>
                                            <input
                                                id={'roleedit_' + item}
                                                value={item}
                                                type="checkbox"
                                                checked={isChecked(item) ?? true}
                                                onChange={handleCheck}
                                                disabled={item === 'Client'}
                                            />
                                            <label
                                                htmlFor={'roleedit_' + item}
                                                className={'noselect'}
                                                style={
                                                    item === 'Client'
                                                        ? {color: '#9e9e9e', cursor: 'not-allowed'}
                                                        : undefined
                                                }
                                            >
                                                {item}
                                            </label>
                                        </li>
                                    );
                                })}
                            </ul>
                        </fieldset>
                    </div>

                    <div className="textboxContainer">
                        <Button
                            text="Edit Backend User"
                            type="success"
                            disabled={backendInformation.disabled}
                            className="addUser"
                            useSubmitBehavior={true}
                            onClick={(e) => checkValidation(e, onSubmit)}
                        />
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default EditBackendUserPopup;

import { LoginDto } from "../../Models/Login/LoginDto";
import StorageHandler from "../../Utils/StorageHandler";
import IdentityServerBaseService from "../../Services/IdentityServerBaseService";
import TokenHandler from "../../Utils/TokenHandler";
import { ChangePasswordDto } from "../../Models/Account/ChangePasswordDto";
import { RefreshTokenDto } from "../../Models/Login/RefreshTokenDto";

import FactoryService from "../FactoryService";

const factoryService = new FactoryService();

class AuthService {
  private GET_TOKEN_ENDPOINT: string = "connect/token";
  private POST_CHANGE_NEW_PASSWORD_ENDPOINT: string = "credentials";

  async login(data: LoginDto, callback: () => void) {
    try {
      await (await factoryService.create(IdentityServerBaseService))
        .postAsJson(this.GET_TOKEN_ENDPOINT, data, false)
        .then((result) => {
          TokenHandler.setToken(result.access_token);
          TokenHandler.setRefreshToken(result.refresh_token);
          StorageHandler.SaveToLocalStorage("userEmail", data.username);
          callback();
        });
    } catch (error) {
      throw error;
    }
  }

  async refreshToken(data: RefreshTokenDto, callback: () => void) {
    try {
      await (await factoryService.create(IdentityServerBaseService))
        .postAsJson(this.GET_TOKEN_ENDPOINT, data, false)
        .then((result) => {
          TokenHandler.setToken(JSON.stringify(result));
          callback();
        });
    } catch (error) {
      throw error;
    }
  }

  logout() {
    StorageHandler.Clear();
  }

  isAuthenticated() {
    var token = TokenHandler.GetToken();

    return !!token;
  }

  getUserInfo() {
    return StorageHandler.Get("userEmail");
  }

  async changePassword(data: ChangePasswordDto, callback: (res: any) => void) {
    try {
      const res = await (
        await factoryService.create(IdentityServerBaseService)
      ).postAsJson(this.POST_CHANGE_NEW_PASSWORD_ENDPOINT, data, true);
      if (res) {
        StorageHandler.Clear();
        callback(res);
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new AuthService();

import axios from "axios";
import envConfig from "../configs/env.config";
import {RefreshTokenDto} from "../Models/Login/RefreshTokenDto";
import TokenHandler from "../Utils/TokenHandler";

const instance = axios.create();
const POST_REFRESH_TOKEN_ENDPOINT: string = "connect/token";

instance.interceptors.request.use(
    (req) => {
        return {
            ...req,
        };
    },
    (err) => {
        return Promise.reject();
    }
);

instance.interceptors.response.use(
    (res) => {
        return {...res};
    },
    async function (err) {
        const originalRequest = err.config

        if ((err.response.status === 401 || err.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            let overWatchUrl = envConfig.REACT_APP_IDENTITYSERVER_API_BASE_URL;

            let refreshToken: RefreshTokenDto = {
                refresh_token: TokenHandler.GetRefreshToken(),
                grant_type: "refresh_token"
            };

            let returnTkn = await instance.post(overWatchUrl + POST_REFRESH_TOKEN_ENDPOINT, refreshToken, {
                headers: {
                    'Authorization': "Basic " + envConfig.CLIENT_SECRET,
                    'content-type': 'application/json'
                }
            })
                .then((result) => {
                    TokenHandler.setToken(result.data.access_token);
                    TokenHandler.setRefreshToken(result.data.refresh_token);

                    return result.data.access_token;
                }).catch((error) => {
                    console.error(error);
                    TokenHandler.ClearAll();
                    window.location.replace("/login");
                    return Promise.reject(err);
                });


            originalRequest.headers['authorization'] = 'Bearer ' + returnTkn;
            return instance(originalRequest);
        } else if (err.response.status === 400) {
            return Promise.reject(err);
        } else {
            TokenHandler.ClearAll();
            window.location.replace("/login");
            return Promise.reject(err);
        }
    }
);
export default instance;

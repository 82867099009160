import Button from 'devextreme-react/button';
import DataGrid, {
  Editing,
  LoadPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { EventInfo } from 'devextreme/events';
import { ClickEvent } from 'devextreme/ui/button';
import React, { useEffect } from 'react';
import './Grid.scss';
import Pagination from './PaginationComponent';

interface Props {
  Data: any;
  keyExpr?: string;
  allowUpdating?: boolean;
  allowDeleting?: boolean;
  allowAdding?: boolean;
  defaultPagination?: boolean;
  defaultPageSize?: string | number;
  allowBuiltInAdding?: boolean;
  addButtonEvent?: ((e: ClickEvent) => void) | undefined;
  onRowUpdated?: ((e: EventInfo<any>) => void) | undefined;
  onRowRemoved?: ((e: EventInfo<any>) => void) | undefined;
  onRowInserted?: ((e: EventInfo<any>) => void) | undefined;
  gotoPageCallback?: (pageNumber: number) => void | undefined;
  PaginationData?: any;
  customColumns?: JSX.Element[] | undefined;
  addBtnText?: string;
  allowSearching?: boolean;
}
export const Grid: React.FC<Props> = ({
  Data,
  keyExpr,
  onRowUpdated,
  onRowRemoved,
  onRowInserted,
  allowUpdating,
  allowDeleting,
  allowAdding,
  allowBuiltInAdding,
  addBtnText,
  defaultPageSize = 10,
  defaultPagination,
  addButtonEvent,
  gotoPageCallback,
  customColumns,
  PaginationData,
  allowSearching,
}) => {
  function renderAddButton() {
    if (allowAdding && !allowBuiltInAdding) {
      return (
        <div id="header">
          <Button
            type="default"
            text={addBtnText ?? 'Add'}
            onClick={addButtonEvent}
          />
        </div>
      );
    }
  }
  useEffect(() => {}, [1]);

  return (
    <div>
      {renderAddButton()}
      <DataGrid
        id="clientDataGrid"
        dataSource={Data}
        keyExpr={keyExpr}
        showBorders={true}
        onRowUpdated={onRowUpdated}
        onRowRemoved={onRowRemoved}
        onRowInserted={onRowInserted}
        className={['grid-table'].join(' ')}
      >
        {allowSearching && <SearchPanel visible={true} />}
        <LoadPanel enabled={true} />
        <Scrolling
          useNative={false}
          scrollByContent={true}
          scrollByThumb={true}
          showScrollbar="onHover"
        />
        <Editing
          mode="row"
          allowUpdating={allowUpdating}
          allowDeleting={allowDeleting}
          allowAdding={allowBuiltInAdding}
        />
        {customColumns}
        {defaultPagination && (
          <Paging enabled={true} defaultPageSize={defaultPageSize} />
        )}
        <Pager allowedPageSizes={10} showPageSizeSelector={false} />
      </DataGrid>
      {gotoPageCallback && (
        <Pagination
          paginationData={PaginationData}
          gotoPage={gotoPageCallback}
        />
      )}
    </div>
  );
};

export function DownloadBase64File(
  contentType: string,
  fileName: string,
  base64Data: string
) {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const link = document.createElement("a");
  link.href = linkSource;
  link.download = fileName;
  link.click();
}

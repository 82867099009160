import ButtonBox from "devextreme-react/button";
import {
    Button,
    Column,
    DataGrid,
    Editing,
    LoadPanel as GridLoadPanel,
    Lookup,
    SearchPanel,
} from "devextreme-react/data-grid";

import {confirm} from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, {useEffect, useState} from "react";

import AddBackendUserPopup from "./AddBackendUser";
import EditBackendUserPopup from "./EditBackendUser";
import ResetPasswordPopup from "./ResetPassword/ResetPasswordPopup";

import ClientsService from "../../Services/Clients/ClientsService";
import RolesService from "../../Services/Roles/Roles";
import BackendUsersService from "../../Services/BackendUsers/BackendUsersService";

import UserDto, {ClientFilterDto} from "../../Models/Users/UserDto";

import DisableIcon from "../../Assets/images/disable.png";
import EnableIcon from "../../Assets/images/enable.png";
import PasswordIcon from "../../Assets/images/password.png";

import StorageHandler from "../../Utils/StorageHandler";

import Loader from "../../Components/Loader";

import {SelectBox} from "devextreme-react";
import {CreateIdsForSelectBoxItems} from "../../Utils/CreateIdsForSelectBoxItems";

import "./BackendUsers.scss";

function BackendUsers() {
    const [isLoading, setisLoading] = useState(false);

    const [passwordDisabled, setpasswordDisabled] = useState(true);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showAddPanel, setShowAddPanel] = useState(false);
    const [showEditPanel, setShowEditPanel] = useState(false);
    const [usersList, setUsersList] = useState<UserDto[]>();
    const [rolesList, setRolesList] = useState<string[]>();
    const [clientsList, setClientsList] = useState<string[]>();

    const [loginUserEmail, setLoginUserEmail] = useState("");
    const [resetPasswordEmail, setResetPasswordEmail] = useState("");

    const [password, setPassword] = useState("");
    const [clientFilter, setClientFilter] = useState([]);
    const [roleFilter, setRoleFilter] = useState([]);
    const [selectedClientFilter, setSelectedClientFilter] = useState();
    const [selectedRoleFilter, setSelectedRoleFilter] = useState();


    const [backendUserInformation, setBackendUserInformation] = useState<UserDto>({
        Email: "",
        Name: "",
        FamilyName: "",
        Roles: [],
        Clients: [],
        Password: "",
        disabled: false,
    });

    useEffect(() => {
        const email = StorageHandler.Get("userEmail");
        email && setLoginUserEmail(email);
        getRolesData();
        getClientsData();
    }, []);

    useEffect(() => {
        getBackendUsers();
    }, [selectedClientFilter, selectedRoleFilter]);

    const getRolesData = () => {
        RolesService.GetAllRoles().then((result) => {
            let roleFormat: any = [];
            const excludeRoles = ["User", "SimulationUser"];

            setRolesList(result.filter((role: string) => !excludeRoles.includes(role)));
            result.map((role: any) => {
                if (excludeRoles.includes(role)) {
                    return;
                }

                roleFormat.push({
                    id: role,
                    name: role,
                });
            });
            setRoleFilter(roleFormat);
        });
    };

    const getClientsData = () => {
        ClientsService.GetList().then((result) => {
            let clientListData: any = [];
            const filteredClients = result
                .map((s) => s.clientId);
            filteredClients.map((client) => {
                clientListData.push({
                    id: client,
                    name: client,
                });
            });

            setClientsList(filteredClients);
            setClientFilter(clientListData);
        });
    };

    const getBackendUsers = () => {
        setisLoading(true);

        const payload: ClientFilterDto = {
            roles: selectedRoleFilter ? [selectedRoleFilter] : [],
            clients: selectedClientFilter ? [selectedClientFilter] : [],
        };

        BackendUsersService.BackendUserFilter(payload)
            .then((result) => {
                setUsersList(result);
                setisLoading(false);
            })
            .catch((e) => {
                setisLoading(false);
            });
    };

    const UpdateData = async (data: any) => {
        const dataMapped = data.data as UserDto;

        await BackendUsersService.UpdateBackendUser(dataMapped)
            .then((r) => {
                getBackendUsers();
            })
            .catch((e) => alert(e));
    };

    const DeleteUser = React.useCallback((e) => {
        let result = confirm(
            "<i>Are you sure you want to delete user <b>" +
            e.row.data.email +
            "</b>?</i>",
            "Confirm Backend User Deletion"
        );

        result.then((dialogResult) => {
            if (dialogResult) {
                BackendUsersService.DeleteBackendUser(e.row.data.email)
                    .then((r) => {
                        notify(e.row.data.email + " deleted successfully", "success", 3000);
                    })
                    .catch((e) => {
                        notify(
                            "Failed deleting " + e.row.data.email + ": " + e,
                            "error",
                            3000
                        );
                    })
                    .finally(() => getBackendUsers());
            }
        });
    }, []);

    const handleResetPopup = () => setShowResetPassword(false);

    const showAddPopup = () => {
        setBackendUserInformation({
            Email: "",
            Name: "",
            FamilyName: "",
            Roles: [],
            Clients: [],
            Password: "",
            disabled: false,
        });

        setpasswordDisabled(false);
        setShowEditPanel(false);
        setShowAddPanel(true);
    }

    const showEditPopup = (element: any) => {
        setpasswordDisabled(true);
        const data = element.row.data;

        setBackendUserInformation({
            ...backendUserInformation,
            disabled: data.disabled,
            Email: data.email,
            Name: data.name,
            FamilyName: data.familyName,
            Roles: data.roles,
            Clients: data.clients,
        });

        setShowAddPanel(false);
        setShowEditPanel(true);
    }

    const handleEnableDisableUser = (data: any) => {
        const userEmail = data.row.data.email;
        const disabled = data.row.data.disabled ? true : false;

        BackendUsersService.ToggleBackendUserStatus(userEmail, disabled).then((res) =>
            getBackendUsers()
        );
    };

    const showLockoutIcon = (data: any) => {
        const userEmail = data.row.data.email;
        const disabled = data.row.data.disabled;
        return disabled ? (
            <div className="lockout-user">
                <img src={DisableIcon} alt="disable backend user"/>
                {""}
                {userEmail}
            </div>
        ) : (
            <>{userEmail}</>
        );
    };
    const handleButtonVisibility = (data: any) => {
        return data.row.data.disabled;
    };

    function handlePanel() {
        return (
            <div>
                <h1>Backend Users</h1>
                <div>Manage backend users from here.</div>

                <div className="user-filter-container">
                    <ButtonBox
                        text="Add Backend User"
                        className="addNewBackendUser"
                        onClick={showAddPopup}
                    ></ButtonBox>

                    <SelectBox
                        id="roleFilter"
                        dataSource={roleFilter}
                        valueExpr="id"
                        value={selectedRoleFilter}
                        displayExpr="name"
                        placeholder="Filter by Role"
                        showClearButton={true}
                        className="user-filter"
                        inputAttr={{
                            id: "usersRoleFilter",
                        }}
                        onContentReady={CreateIdsForSelectBoxItems}
                        onValueChange={(e) => {
                            setSelectedRoleFilter(e);
                        }}
                    />
                </div>

                <DataGrid
                    keyExpr="email"
                    id="gridContainer"
                    className="grid-table"
                    dataSource={usersList}
                    onRowPrepared={(e) => {
                        const disabled = e?.data?.disabled;
                        if (disabled) {
                            e.rowElement.classList.add("selected");
                        }
                    }}
                    onRowUpdated={(e) => {
                        UpdateData(e);
                    }}
                    showBorders={true}
                >
                    <GridLoadPanel enabled={false}/>
                    <SearchPanel visible={true}/>
                    <Editing
                        mode="row"
                        useIcons={true}
                        allowUpdating={true}
                        allowDeleting={true}
                    />
                    <Column
                        dataField="email"
                        caption="Email"
                        type="buttons"
                        width="auto"
                        allowEditing={false}
                        cssClass={"email-column"}
                    >
                        <Button
                            icon={PasswordIcon}
                            cssClass={"enable-btn"}
                            onClick={(e: any) => {
                                const email = e.row.data.email;
                                if (!email) {
                                    notify("Email is not valid", "error", 3000);
                                    return;
                                }

                                setResetPasswordEmail(email);
                                setShowResetPassword(!showResetPassword);
                            }}
                        />

                        <Button render={showLockoutIcon}/>
                    </Column>
                    <Column dataField="name" caption="FirstName"/>
                    <Column dataField="familyName" caption="LastName"/>

                    <Column
                        dataField="accessFailedCount"
                        caption="AccessFailedCount"
                    />
                    <Column type="buttons" buttons={
                        [
                            {
                                name: "editBackendUsers",
                                onClick: showEditPopup,
                                visible: ((e: any) => e.row.data.email !== loginUserEmail),
                                icon: "edit",
                            },
                            {
                                name: "deleteBackendUsers",
                                onClick: DeleteUser,
                                visible: ((e: any) => e.row.data.email !== loginUserEmail),
                                icon: "trash",
                            },
                            {
                                name: "enableBackendUsers",
                                onClick: handleEnableDisableUser,
                                visible: ((e: any) => handleButtonVisibility(e) && e.row.data.email !== loginUserEmail),
                                icon: EnableIcon,
                            },
                            {
                                name: "disableBackendUsers",
                                onClick: handleEnableDisableUser,
                                visible: ((e: any) => !handleButtonVisibility(e) && e.row.data.email !== loginUserEmail),
                                icon: DisableIcon,
                            },
                        ]
                    } width="auto" allowEditing={false}></Column>
                </DataGrid>

                <Loader isLoaderVisible={isLoading}/>

                {
                    <ResetPasswordPopup
                        handleResetPopup={handleResetPopup}
                        showPopup={showResetPassword}
                        email={resetPasswordEmail}
                    />
                }

                <AddBackendUserPopup
                    showAddPanel={showAddPanel}
                    closePopUpHandler={setShowAddPanel}
                    getBackendUsers={getBackendUsers}
                    roleLookupData={rolesList ?? []}
                    backendInformation={backendUserInformation}
                    setBackendUserInformation={setBackendUserInformation}
                    passwordDisabled={passwordDisabled}
                    password={password}
                    setPassword={setPassword}
                />

                <EditBackendUserPopup
                    showEditPanel={showEditPanel}
                    closePopUpHandler={setShowEditPanel}
                    getBackendUsers={getBackendUsers}
                    roleLookupData={rolesList ?? []}
                    backendInformation={backendUserInformation}
                    setBackendUserInformation={setBackendUserInformation}
                    password={password}
                    setPassword={setPassword}
                />
            </div>
        );
    }

    return <div id="clientGrid">{handlePanel()}</div>;
}

export default BackendUsers;

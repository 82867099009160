import {ContentTypes} from '../Constants/ContentTypes';
import {HttpHeaderBuilder} from '../Utils/HttpHeaderBuilder';
import TokenHandler from '../Utils/TokenHandler';
import envConfig from '../configs/env.config';
import axios from './axiosConfig';

export class BaseService {
    private httpPattern = /^(http|https)/;
    public baseUrl: string = '';

    async postAsJson(
        url: string,
        data: any,
        hasAuthToken: boolean,
        isMultipart: boolean = false
    ): Promise<any> {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(isMultipart ? ContentTypes.MULTIPART_FORM_DATA : ContentTypes.JSON, token || '');
        url = this.validateUrl(url);

        const response = await axios({
            method: 'POST',
            headers: header,
            url: url,
            data: isMultipart ? data : JSON.stringify(data),
        });
        if (response?.status != 200) {
            return Promise.reject(response.data);
        }

        return response?.data ?? null;
    }

    async postAsFile(
        url: string,
        data: any,
        hasAuthToken: boolean
    ): Promise<any> {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(
            ContentTypes.MULTIPART_FORM_DATA,
            token || ''
        );
        url = this.validateUrl(url);

        const response = await axios({
            method: 'POST',
            headers: header,
            url: url,
            data: data,
        });
        if (response.status !== 200) {
            return Promise.reject(response.data);
        }

        return response?.data ?? null;
    }

    async getAsJson(url: string, hasAuthToken: boolean) {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(ContentTypes.JSON, token || '');
        url = this.validateUrl(url);
        const response = await axios({
            method: 'GET',
            headers: header,
            url: url,
        });
        if (response.status !== 200) {
            return Promise.reject(response.data);
        }
        return response?.data ?? null;
    }

    async putAsJson(url: string, data: any, hasAuthToken: boolean) {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(ContentTypes.JSON, token || '');
        url = this.validateUrl(url);

        const response = await axios({
            method: 'PUT',
            headers: header,
            url: url,
            data: JSON.stringify(data),
        });
        if (response.status !== 200 && response.status !== 204) {
            return Promise.reject(response.data);
        }
        return response.data;
    }

    async patchAsJson(url: string, data: any, hasAuthToken: boolean) {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(ContentTypes.JSON, token || '');
        url = this.validateUrl(url);

        const response = await axios({
            method: 'PATCH',
            headers: header,
            url: url,
            data: JSON.stringify(data),
        });
        if (response.status !== 200 && response.status !== 204) {
            return Promise.reject(response.data);
        }
        return response.data;
    }

    async delete(url: string, hasAuthToken: boolean, data?: string) {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(ContentTypes.JSON, token || '');
        url = this.validateUrl(url);

        const response = await axios({
            method: 'DELETE',
            headers: header,
            url: url,
            data: JSON.stringify(data),
        });
        if (response.status !== 200 && response.status !== 204) {
            return Promise.reject(response.data);
        }
        return response.data;
    }

    async downLoadFile(
        url: string,
        hasAuthToken: boolean,
        givenFileName?: string
    ) {
        let CLIENT_KEY = envConfig.CLIENT_SECRET;

        let token = hasAuthToken
            ? 'Bearer ' + TokenHandler.GetToken()
            : `Basic ${CLIENT_KEY}`;
        let header = HttpHeaderBuilder.Build(ContentTypes.JSON, token || '');
        url = this.validateUrl(url);
        return await axios({
            method: 'GET',
            headers: header,
            url: url,
            responseType: 'blob',
        }).then((response) => {
            if (response.status !== 200) {
                return Promise.reject(response.data);
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let dispositionFileName = response?.headers['content-disposition']
                ?.split(';')[1]
                .trim()
                ?.replace('filename=', '')
                ?.replaceAll('"', '')
                ?.replaceAll('"', '');
            const defaultName = givenFileName
                ? givenFileName + '.xlsx'
                : 'downloaded_file.xlsx';
            link.download = dispositionFileName || defaultName;

            link.click();
        });
    }

    private validateUrl(url: string) {
        if (this.httpPattern.test(url) === false) {
            return this.baseUrl + url;
        }
        return url;
    }
}

import {Button, Column} from 'devextreme-react/data-grid';
import {Grid} from '../../../../Components/Grid/Grid';
import React from "react";
import DisableIcon from "../../../../Assets/images/disable.png";
import {ROUTES} from "../../../../routes";
import EnableIcon from "../../../../Assets/images/enable.png";
import {useNavigate} from "react-router-dom";
import UsersService from "../../../../Services/Users/Users";
import Pagination from "../../../../Components/Grid/PaginationComponent";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

type Props = {
    customerReportData: Array<any>;
    paginationData: any;
    handleFilterCustomerReport: (pageNumber?: number) => void;
};

const CustomerReportGrid = ({customerReportData, handleFilterCustomerReport, paginationData}: Props) => {
    const navigate = useNavigate();
    const showLockoutIcon = (data: any) => {
        const userEmail = data.row.data.email;
        const disabled = data.row.data.disabled;
        return disabled ? (
            <div className="lockout-user">
                <img src={DisableIcon} alt="disable user"/>
                {""}
                {userEmail}
            </div>
        ) : (
            <>{userEmail}</>
        );
    };

    const handleEnableDisableUser = (data: any) => {
        const userName = data.row.data.userName;
        const disabled = !!data.row.data.disabled;

        UsersService.ToggleUserStatus(userName, disabled).then((res) =>
            handleFilterCustomerReport()
        );
    };

    const handleButtonVisibility = (data: any) => {
        return data.row.data.disabled;
    };


    const gridColumns = [
        <Column
            key="createdOn"
            dataField={'createdOn'}
            caption={'Registration Date'}
            alignment={'left'}
            cellRender={(cellData) => {
                const dateCreated = cellData.data.createdOn;

                return (
                    <>{(dateCreated !== null) ? new Date(dateCreated).toLocaleDateString('en-GB') || "" : "N/A"}</>
                );
            }}
        />,
        <Column
            key="phoneNumber"
            dataField={'phoneNumber'}
            caption={'Mobile'}
            alignment={'left'}
        />,
        <Column
            dataField="email"
            caption="Email"
            type="buttons"
            width="auto"
            allowEditing={false}
            cssClass={"email-column"}
        >
            <Button render={showLockoutIcon}/>
        </Column>,
        <Column
            key="name"
            dataField={'name'}
            caption={'Name'}
            alignment={'left'}
        />,
        <Column
            key="familyName"
            dataField={'familyName'}
            caption={'Surname'}
            alignment={'left'}
        />,
        <Column
            key="taxCountry"
            dataField={'taxCountry'}
            caption={'Tax country'}
            alignment={'left'}
            cellRender={(cellData) => {
                const taxCountry = cellData.data.taxCountry;
                return <>{taxCountry || 'N/A'}</>;
            }}
        />,
        <Column
            caption="Bot Access"
            cellRender={(e: any) => {
                return (
                    <div>
                        {e.data.userExternalReference === null || e.data.userExternalReference === "" || e.data.userExternalReference === undefined ?
                            "❌" : "✅"
                        }
                    </div>
                )
            }}
        />,
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            <Column
                key="credit"
                dataField={'credit'}
                caption={'Credit balance'}
                alignment={'left'}
                cellRender={(cellData) => {
                    const creditBalance = cellData.data.creditBalance;
                    const currencyIso3 = cellData.data.currencyIso3 || "EUR";
                    return <>{`${currencyIso3} ${creditBalance}`}</>;
                }}
                calculateSortValue={(rowData: { creditBalance: any; }) => rowData.creditBalance}
            />,
        ] : [],
        <Column type="buttons" buttons={
            [
                {
                    name: "customerTransactions",
                    text: "Transactions",
                    onClick: (e: any) => {
                        const externalReference = e.row.data.userExternalReference;

                        const transactionUserList = customerReportData?.filter((user: any) => user.userExternalReference !== null && user.userExternalReference !== "" && user.userExternalReference !== undefined);

                        navigate(ROUTES.TRANSACTIONS, {
                            state: {
                                externalReference: externalReference,
                                transactionUserList: transactionUserList
                            },
                        });
                    },
                    disabled: (e: any) => {
                        return e.row.data.userExternalReference === null || e.row.data.userExternalReference === "" || e.row.data.userExternalReference === undefined;
                    },
                    icon: "money",
                },
                // {
                //     name: "editCustomers",
                //     onClick: showEditPopup,
                //     visible: true,
                //     icon: "edit",
                // },
                {
                    name: "enableCustomers",
                    onClick: handleEnableDisableUser,
                    visible: handleButtonVisibility,
                    icon: EnableIcon,
                },
                {
                    name: "disableCustomers",
                    onClick: handleEnableDisableUser,
                    visible: (e: any) => !handleButtonVisibility(e),
                    icon: DisableIcon,
                },
            ]
        } width="auto" allowEditing={false}></Column>


    ];

    return (
        <>
            <div key={'TopupGrid'}>
                <Grid
                    Data={customerReportData}
                    keyExpr="email"
                    customColumns={gridColumns}
                    defaultPagination
                />

                <Pagination
                    paginationData={paginationData}
                    gotoPage={handleFilterCustomerReport}
                />
            </div>
        </>
    );
};

export default CustomerReportGrid;

import './Heading.scss';
type Props = {
  level: number;
  textContent: string;
  style?: React.CSSProperties;
};

export const Heading = ({ level, textContent, style }: Props) => {
  const RenderHeadElement = (textContent: string) => {
    let headElement;
    switch (level) {
      case 1:
        return (headElement = (
          <h1
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h1>
        ));
      case 2:
        return (headElement = (
          <h2
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h2>
        ));
      case 3:
        return (headElement = (
          <h3
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h3>
        ));
      case 4:
        return (headElement = (
          <h4
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h4>
        ));
      case 5:
        return (headElement = (
          <h5
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h5>
        ));
      case 6:
        return (headElement = (
          <h6
            style={style}
            className={['heading', `heading--${level}`].join(' ')}
            data-testid="Heading"
          >
            {textContent}
          </h6>
        ));
    }
    return headElement;
  };
  return <>{RenderHeadElement(textContent)}</>;
};

import {
  Column,
  DataGrid,
  LoadPanel as GridLoadPanel,
} from 'devextreme-react/data-grid';

import { useEffect, useState } from 'react';

import ClientDto from '../../Models/Clients/ClientDto';

import ClientsService from '../../Services/Clients/ClientsService';

import Loader from '../../Components/Loader';

// import 'devextreme/dist/css/dx.light.css';
import './Clients.scss';

function Clients() {
  const [isLoadPanelVisible, changeLoadPanelVisible] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<ClientDto[]>();

  useEffect(() => {
    getClientsData();
  }, [1]);

  const getClientsData = () => {
    changeLoadPanelVisible(true);
    ClientsService.GetList()
      .then((result) => {
        setClientsList(result);
        changeLoadPanelVisible(false);
      })
      .catch((e) => {
        console.log(e);
        changeLoadPanelVisible(false);
      });
  };

  function handlePanel() {
    return (
      <div>
        <h1>Clients</h1>
        <div className="description">
          View the list of clients supported by the system.
        </div>

        <DataGrid
          keyExpr="clientId"
          className="grid-table"
          id="gridContainer"
          dataSource={clientsList}
          showBorders={true}
        >
          <GridLoadPanel enabled={false} />
          <Column dataField="clientId" caption="Client Id" />
          <Column dataField="enabled" caption="Enabled" width={80} />
          <Column dataField="allowedScopes" caption="Scopes" />
          <Column
            dataField="accessTokenLifetime"
            caption="Token Lifetime"
            width={120}
          />
          <Column
            dataField="slidingRefreshTokenLifetime"
            caption="Sliding Refresh Token Lifetime"
            width={200}
          />
        </DataGrid>

        <Loader isLoaderVisible={isLoadPanelVisible} />
      </div>
    );
  }

  return <div id="clientGrid">{handlePanel()}</div>;
}

export default Clients;

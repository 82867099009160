import { Outlet } from "react-router";

type Props = {};

export function Main({}: Props) {
  return (
    <main id="content" className="main-app">
      <Outlet />
      {/* <AppVersion /> */}
    </main>
  );
}

import { ContentTypes } from "../Constants/ContentTypes";
import { ContentTypeBuilder } from "./ContentTypeBuilder";

export class HttpHeaderBuilder {
  static Build(contentType: ContentTypes, authorization: string) {
   
    let header = {   
      "Content-Type": ContentTypeBuilder.GetContetnType(contentType),
      authorization: authorization,
    };

    return header;
  }
}

import { ContentTypes } from "../Constants/ContentTypes";

export class ContentTypeBuilder {
  static GetContetnType(type: ContentTypes) {
    switch (type) {
      case ContentTypes.JSON:
        return "application/json";
      case ContentTypes.URL_ENCODED:
        return "application/x-www-form-urlencoded";
      case ContentTypes.MULTIPART_FORM_DATA:
        return 'multipart/form-data';
      default:
        return "application/json";
    }
  }
}

import FactoryService from "../FactoryService";
import {ClientFilterDto, UserDto} from "../../Models/Users/UserDto";
import {ResetPasswordDto} from "../../Models/Users/ResetPasswordDto";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class BackendUsersService {

    private USERS_RESET_PASSWORD = "Password/overwrite";

    private BACKEND_USERS_ENDPOINT = "Staffs";

    async AddBackendUser(user: UserDto) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).postAsJson(this.BACKEND_USERS_ENDPOINT, user, true);
        if (result) {
            return result;
        }
    }

    async DeleteBackendUser(email: string) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).delete(this.BACKEND_USERS_ENDPOINT + "/" + email, true);
        if (result) {
            return result;
        }
        return null;
    }

    async UpdateBackendUser(user: UserDto) {
        const result = await (
            await factoryService.create(AuthBaseService)
        ).putAsJson(this.BACKEND_USERS_ENDPOINT, user, true);
        if (result) {
            return result;
        }
        return null;
    }

    async ToggleBackendUserStatus(email: string, status: boolean) {

        const payload = {
            enabled: status,
            ...(!status && {lockedMonthsOut: 60})
        };

        const result = await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(`${this.BACKEND_USERS_ENDPOINT}/${email}/lockedout`, payload, true);

        if (result) {
            return result;
        }
        return null;
    }

    async ResetPassword(email: string, newPassword: ResetPasswordDto) {
        const url = `${this.USERS_RESET_PASSWORD}/${email}`;
        const result = await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(url, newPassword, true);
        if (result) {
            return result;
        }
        return null;
    }

    async BackendUserFilter(payload: ClientFilterDto) {
        let queryString = "?";
        if (payload.roles) {
            payload.roles.forEach((role) => {
                queryString += `roles=${role}&`;
            });
        }
        if (payload.clients) {
            payload.clients.forEach((client) => {
                queryString += `clients=${client}&`;
            });
        }

        queryString = queryString.slice(0, -1);

        const result = await (
            await factoryService.create(AuthBaseService)
        ).getAsJson(`${this.BACKEND_USERS_ENDPOINT}${queryString}`, true);
        if (result) {
            return result;
        }
    }
}

export default new BackendUsersService();

const {
  REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  CONTACT: '/contact',
  CLIENTS: '/clients',
  USERS: '/users',
  ROLES: '/roles',
  ACCOUNT: '/account',
  DASHBOARD: '/dashboard',
  BACKEND_USERS: '/backend-users',
  ...REACT_APP_PAYMENT_MODEL === 'CREDIT' && {
    CREDIT: '/credit',
    RULES: '/rules',
    SIMULATIONS: '/simulations',
  },
  BLACKOUT: '/blackout',
  TRANSACTIONS: '/transactions',
  CUSTOMERSREPORT: '/customers-report',
  GAMERESULTSREPORT: '/gameresult-report',
  PAYMENTSREPORT: '/payments-report',
  REVENUEREPORT: '/revenue-report',
  BROADCAST: '/broadcast',
  RESET_CACHE: '/reset-cache',
};

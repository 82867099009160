import FactoryService from "../FactoryService";
import { ChangePasswordDto } from "../../Models/Account/ChangePasswordDto";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class AccountService {
    private CREDENTIALS_ENDPOINT = "Password/change";

    async ChangePassword(data: ChangePasswordDto) {
        const response = await (
            await factoryService.create(AuthBaseService)
        ).patchAsJson(this.CREDENTIALS_ENDPOINT, data, true);
        return response;
    }
}

export default new AccountService();

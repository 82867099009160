import FactoryService from "../FactoryService";
import TipBaseService from "../TipBaseService";

const factoryService = new FactoryService();

class RuleService {
    private RULES_ENDPOINT = "Rules";
    private RULES_SIMULATED_MESSAGE_ENDPOINT = "Rules/sendTelegramTestMessage";

    async getRules() {
        const response = await (
            await factoryService.create(TipBaseService)
        ).getAsJson(this.RULES_ENDPOINT, true);
        return response;
    }

    async updateRules(payload: any) {
        const response = await (
            await factoryService.create(TipBaseService)
        ).patchAsJson(this.RULES_ENDPOINT, payload, true);
        return response;
    }

    async sendSimulatedMessage(ruleName: string) {
        const response = await (
            await factoryService.create(TipBaseService)
        ).postAsJson(`${this.RULES_SIMULATED_MESSAGE_ENDPOINT}/${ruleName}`, {ruleName}, true);
        return response;
    }

}

export default new RuleService();

import {Button, DateBox, Validator} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import {useEffect, useState} from 'react';
import Loader from '../../../../Components/Loader';
import {TOASTER_DELAY} from '../../../../Constants/values';
import checkValidation from '../../../../Utils/FormValidation';
import '../GameResultReport.scss';
import TextBox from "devextreme-react/text-box";
import ReportingService from "../../../../Services/Reporting/ReportingService";
import {GameResultReportPayloadDTO} from "../../../../Models/Reports/GameResultReport";

type Props = {
    gameResultPayload: GameResultReportPayloadDTO;
    setgameResultPayload: React.Dispatch<GameResultReportPayloadDTO>;
    isLoading: boolean;
    handleFilterGameReport: () => void;
};

const GameResultReportFilter = ({
                                    gameResultPayload,
                                    setgameResultPayload,
                                    isLoading: Loading,
                                    handleFilterGameReport,
                                }: Props) => {

        return (
            <article className="topup__filter">
                <div className="topup__filter-fields">
                    <DateBox
                        id="topup__startDate"
                        placeholder="Date from"
                        inputAttr={{
                            id: 'transactions-startDate',
                        }}
                        // displayFormat="dd/MM/yyyy"
                        value={gameResultPayload.startDate ?? undefined}
                        onValueChanged={(e) => {
                            const payload: GameResultReportPayloadDTO = {
                                ...gameResultPayload,
                                startDate: e.value,
                            };
                            setgameResultPayload(payload);
                        }}
                    >
                        <Validator validationGroup="TopupValidator">
                            <RequiredRule message="Start Date is required"/>
                        </Validator>
                    </DateBox>
                    <DateBox
                        id="topup__endDate"
                        placeholder="Date to"
                        inputAttr={{
                            id: 'transactions-endDate',
                        }}
                        value={gameResultPayload.endDate ?? undefined}
                        onValueChanged={(e) => {
                            const payload: GameResultReportPayloadDTO = {
                                ...gameResultPayload,
                                endDate: e.value,
                            };
                            setgameResultPayload(payload);
                        }}
                    >
                        <Validator validationGroup="TopupValidator">
                            <RequiredRule message="End Date is required"/>
                        </Validator>
                    </DateBox>
                </div>

                <Button
                    style={{marginTop: '15px'}}
                    text="Search"
                    type="default"
                    id="transactionsSearch"
                    useSubmitBehavior={true}
                    validationGroup="TopupValidator"
                    stylingMode="contained"
                    disabled={
                        !gameResultPayload.startDate && !gameResultPayload.endDate
                    }
                    onClick={(e) => checkValidation(e, handleFilterGameReport)}
                />

                <Loader isLoaderVisible={Loading}/>
            </article>
        );
    }
;

export default GameResultReportFilter;

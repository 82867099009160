import {Button} from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import {useState} from 'react';
import Loader from '../../../../Components/Loader';
import {TOASTER_DELAY} from '../../../../Constants/values';
import {TopupReportPayloadDTO} from '../../../../Models/TopupReport/TopupReport';
import TopupReport from '../../../../Services/TopupReport/TopupReport';
import {DownloadBase64File} from '../../../../Utils/FileHandler';
import {CustomerReportPayloadDTO} from "../../../../Models/Reports/CustomerReport";
import ReportingService from "../../../../Services/Reporting/ReportingService";

type Props = {
    customerReportPayload: CustomerReportPayloadDTO;
};

const CustomerReportExportBtn = ({customerReportPayload}: Props) => {
    const [Loading, setLoading] = useState(false);
    const handleExport = () => {
        setLoading(true);

        ReportingService.customerReportExport(customerReportPayload)
            .then((res) => {
                DownloadBase64File(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'CustomersReport.xlsx',
                    res
                );
                notify('Successfully downloaded', 'success', TOASTER_DELAY);
                setLoading(false);
            })
            .catch((err) => {
                err?.response?.data.text().then((res: string) => {
                    const errorMsg = res;
                    errorMsg && notify(errorMsg, 'error', TOASTER_DELAY);
                    setLoading(false);
                });
            });
    };

    return (
        <article>
            <Button
                text="Export"
                type="default"
                id="topupSearch"
                useSubmitBehavior={true}
                stylingMode="outlined"
                onClick={handleExport}
            />
            <Loader isLoaderVisible={Loading}/>
        </article>
    );
};

export default CustomerReportExportBtn;

import "./Broadcast.scss";
import {Button, LoadPanel, SelectBox, TextArea} from "devextreme-react";
import {useRef, useState} from "react";
import TextBox from "devextreme-react/text-box";
import UsersService from "../../../Services/Users/Users";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import CreditService from "../../../Services/Credit/CreditService";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";
import Popup from "devextreme-react/popup";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

type BulkCreditProps = {
    credits: string,
    reference: string,
    note: string,
    message: string,
    notifyByTelegram: boolean,
    notifyByEmail: boolean,
    userExternalReferences: string[]
};
const Broadcast = () => {
    const fileUploadRef = useRef() as any;
    const [loading, setLoading] = useState(false);
    const [bulkCreditData, setBulkCreditData] = useState<BulkCreditProps>({
        credits: "",
        reference: "",
        note: "",
        message: "",
        notifyByTelegram: false,
        notifyByEmail: false,
        userExternalReferences: []
    });
    const [fileInputKey, setFileInputKey] = useState(new Date()?.getTime());
    const [uploadedUsers, setUploadedUsers] = useState<any[]>([]);
    const [isResultPopup, setIsResultPopup] = useState(false);
    const [isAddCredit, setIsAddCredit] = useState(false);
    const [responseData, setResponseData] = useState<any>({
        success: [],
        failed: []
    });
    const [notifyOptions, setNotifyOptions] = useState<any>()

    const handleFileUpload = (e: any) => {
        setLoading(true);

        if (!e.target.files) {
            setLoading(false)
            return;
        }

        const formData = new FormData();

        formData.append("file", e.target.files[0]);

        UsersService.UploadUsersList(formData)
            .then((result) => {
                setLoading(false)
                setUploadedUsers(result);

                setBulkCreditData({
                    ...bulkCreditData,
                    userExternalReferences: result.map((user: {
                        userExternalReference: string;
                    }) => user.userExternalReference)
                });

            })
            .catch((e) => {
                const error = e?.response?.data?.Error?.Message || "Something went wrong while trying to upload the users list."
                notify(error, 'error', TOASTER_DELAY);
                setLoading(false)
            });
    }

    const bulkCreditHandler = () => {

        if (bulkCreditData.message && !bulkCreditData.notifyByTelegram && !bulkCreditData.notifyByEmail) {
            notify('Please select at least one notify option.', 'error', TOASTER_DELAY);
            return;
        }

        if (bulkCreditData.credits && !bulkCreditData.reference) {
            notify('Reference is required.', 'error', TOASTER_DELAY);
            return;
        }

        setLoading(true);
        CreditService.bulkCredit(bulkCreditData)
            .then((result) => {
                setResponseData(result);

                setLoading(false);
                setIsResultPopup(true)
            })
            .catch((e) => {
                if (!e?.response?.data?.failed?.length) {
                    notify('Something went wrong, Please try again later.', 'error', TOASTER_DELAY);
                } else {
                    const result = {
                        success: e.response.data.success || [],
                        failed: e.response.data.failed || []
                    }
                    setResponseData(result);
                    setIsResultPopup(true)
                }

                setLoading(false);
            });
    }

    const isPartiallyRegistered = uploadedUsers.some((user) => user.phoneNumberConfirmed === false);

    const isMissingEmail = uploadedUsers.some((user) => !user.email);

    return (
        <div>
            <h1>Broadcast</h1>
            <div>Distribute free credits between customers by broadcast messages and free credits.</div>
            <Button
                text="Import Customers"
                type="default"
                className="import-customers"
                style={{
                    marginTop: "20px",
                }}
                stylingMode="contained"
                onClick={() => {
                    document.getElementById("upload-customers")?.click();
                }}
            />
            <input
                ref={fileUploadRef}
                name="upload-customers"
                type="file"
                key={fileInputKey}
                style={{display: "none"}}
                onChange={(e) => {
                    if (e.target.files) {
                        handleFileUpload(e);
                    }

                    setFileInputKey(new Date()?.getTime());
                }}
                id={"upload-customers"}
            />

            <div className="bulk-credit-container">
                <div className="form-container">

                    <label className="form-label">
                        Notify Via:
                    </label>

                    <SelectBox
                        items={[
                            {id: 1, name: "Telegram"},
                            {id: 2, name: "Email"},
                        ]}
                        disabled={uploadedUsers.length === 0}
                        valueExpr="id"
                        displayExpr="name"
                        value={notifyOptions}
                        onValueChanged={(e) => {
                            if (e.value === 1) {
                                setBulkCreditData({
                                    ...bulkCreditData,
                                    notifyByTelegram: true,
                                    notifyByEmail: false
                                });
                            } else {
                                setBulkCreditData({
                                    ...bulkCreditData,
                                    notifyByTelegram: false,
                                    notifyByEmail: true
                                });
                            }

                            setNotifyOptions(e.value);
                        }}
                    />

                    {
                        (isPartiallyRegistered && bulkCreditData.notifyByTelegram) &&
                        <div className="partially-registered-alert">
                            <p>
                                Please be aware that some of the imported users are partially registered and, as a
                                result,
                                will not receive the broadcast message through Telegram.
                            </p>
                        </div>
                    }

                    {
                        (isMissingEmail && bulkCreditData.notifyByEmail) &&
                        <div className="partially-registered-alert">
                            <p>
                                Please be aware that some of the imported users are missing email and, as a
                                result,
                                will not receive the broadcast message through Email.
                            </p>
                        </div>
                    }
                    <label className="form-label">
                        Imported customers:
                    </label>
                    <TextArea
                        value={
                            uploadedUsers.map((user) => {
                                return `${user.phoneNumber} - ${user.email ? user.email : "No Email"}`;
                            }).toString()?.replace(/,/g, "\n")
                        }
                        readOnly
                        height={200}
                        className="user-list-textarea"
                    />
                    {
                        REACT_APP_PAYMENT_MODEL === "CREDIT" &&
                        <>
                            <input type="checkbox" disabled={uploadedUsers.length === 0}
                                   id="add-credit" name="add-credit" value="add-credit" style={{
                                marginTop: "20px"
                            }}
                                   onChange={
                                       (e) => {
                                           setIsAddCredit(e.target.checked);

                                           if (!e.target.checked) {
                                               setBulkCreditData({
                                                   ...bulkCreditData,
                                                   credits: "",
                                                   reference: "",
                                                   note: "",
                                               });
                                           }
                                       }
                                   }
                            />
                            <label className="checkbox-label" htmlFor="add-credit">Add Credit</label>
                        </>
                    }

                    {isAddCredit && <>
                        <label className="form-label">
                            Credits:
                        </label>
                        <TextBox
                            value={bulkCreditData.credits}
                            placeholder={'Enter credit amount here'}
                            onValueChanged={(e) => {
                                const creditVal = e.value;
                                setBulkCreditData({
                                    ...bulkCreditData,
                                    credits: creditVal,
                                });
                            }}
                        >
                            <Validator>
                                <PatternRule
                                    message="Invalid credit amount"
                                    pattern={/^[0-9]+$/}
                                />
                            </Validator>
                        </TextBox>

                        <label className="form-label">
                            Reference:
                        </label>
                        <TextBox
                            value={bulkCreditData.reference}
                            placeholder={'Enter reference here'}
                            onValueChanged={(e) => {
                                const referenceVal = e.value;
                                setBulkCreditData({
                                    ...bulkCreditData,
                                    reference: referenceVal,
                                });
                            }}
                        >
                            <Validator>
                                {
                                    !bulkCreditData.credits &&
                                    <RequiredRule message="Reference is required"/>
                                }
                            </Validator>
                        </TextBox>

                        <label className="form-label">
                            Note:
                        </label>
                        <TextArea
                            value={bulkCreditData.note}
                            height={50}
                            className="response-textarea"
                            onValueChanged={(e) => {
                                const noteVal = e.value;
                                setBulkCreditData({
                                    ...bulkCreditData,
                                    note: noteVal,
                                });
                            }}
                        />
                    </>}

                    <label className="form-label">
                        Message:
                    </label>
                    <TextArea
                        disabled={uploadedUsers.length === 0}
                        value={bulkCreditData.message}
                        height={150}
                        className="response-textarea"
                        onValueChanged={(e) => {
                            const messageVal = e.value;
                            setBulkCreditData({
                                ...bulkCreditData,
                                message: messageVal,
                            });
                        }}
                    />

                    <div className="action-container">
                        <Button
                            text="Broadcast"
                            type="default"
                            className="run-free-credit"
                            stylingMode="contained"
                            disabled={loading || uploadedUsers.length === 0 || (isAddCredit ? !bulkCreditData.credits : !bulkCreditData.message)}
                            onClick={() => bulkCreditHandler()}
                        />
                    </div>
                </div>

                <div className="result-container">
                </div>
            </div>

            <Popup
                width={660}
                height={540}
                showTitle={true}
                title="Response"
                dragEnabled={false}
                visible={isResultPopup}
                onHiding={() => setIsResultPopup(false)}
            >
                <div>
                    <h4
                        style={{
                            color: "darkgreen"
                        }}
                    >Success: </h4>
                    <TextArea
                        value={
                            responseData.success.map((user: any) => {
                                    const userDetail = uploadedUsers.find((u) => u.userExternalReference === user);
                                    return `${user?.replace(
                                        userDetail?.userExternalReference,
                                        userDetail?.phoneNumber
                                    )} - ${user?.replace(
                                        userDetail?.userExternalReference,
                                        userDetail?.email
                                    ) || "No Email"}`;
                                }
                            ).toString()?.replace(/,/g, "\n")
                        }
                        readOnly
                        height={200}
                        className="user-list-textarea"
                    />
                    <h4
                        style={{
                            color: "darkred",
                            marginTop: "10px"
                        }}
                    >Error: </h4>
                    <TextArea
                        value={
                            responseData.failed.map((user: any) => {
                                    if (!user?.itemIdentifier) return;

                                    const userDetail = uploadedUsers.find((u) => u.userExternalReference === user?.itemIdentifier);

                                    return `${user.itemIdentifier.replace(
                                        userDetail?.userExternalReference,
                                        userDetail?.phoneNumber
                                    )} - ${user.errorDescription.replace(
                                        "[" + userDetail?.userExternalReference + "]",
                                        userDetail?.phoneNumber
                                    )}`;
                                }
                            ).toString()?.replace(/,/g, "\n")
                        }
                        readOnly
                        height={200}
                        className="user-list-textarea"
                    />
                </div>
            </Popup>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: "#simulations"}}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>
    );
};

export default Broadcast;

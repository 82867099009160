import { useState } from "react";
import "./ResetPasswordPopup.scss";
import { Button, Popup, TextBox, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import backendUsersService from "../../../Services/BackendUsers/BackendUsersService";
import notify from "devextreme/ui/notify";
import checkPasswordValidation from "../../../Utils/PasswordValidation";

type Props = {
  showPopup: boolean;
  handleResetPopup: () => void;
  email: string;
};

const ResetPasswordPopup = ({ showPopup, handleResetPopup, email }: Props) => {
  const [validationMessage, setValidationMessage] = useState<string[]>([]);
  const [password, setPassword] = useState("");
  const handleSubmit = () => {
    const passwordData = {
      newPassword: password,
    };
    backendUsersService.ResetPassword(email, passwordData)
      .then((res) => {
        notify("Successfully Resting the password", "Success");
        handleResetPopup();
      })
      .catch((err) => {
        let validationMessageArray = checkPasswordValidation(password);
        setValidationMessage([...validationMessageArray]);
        notify("Failed Resting the password", "error");
      });
  };
  return (
    <>
      {showPopup && (
        <Popup
          width={500}
          height={400}
          showTitle={true}
          title="Reset Password"
          dragEnabled={false}
          closeOnOutsideClick={true}
          visible={showPopup}
          onHiding={() => {
            setPassword("");
            setValidationMessage([]);
            handleResetPopup();
          }}
        >
          <section className="reset-password">
            <TextBox
              mode="password"
              className="reset-password__input"
              placeholder="Password"
              value={password}
              onValueChange={(e) => setPassword(e)}
            >
              <Validator>
                <RequiredRule message="Password is required" />
              </Validator>
            </TextBox>
            {validationMessage.length > 0 && (
              <ul className="validation-message">
                {validationMessage.map((msg: string) => (
                  <li key={msg} className="validation-message__item">
                    {msg}
                  </li>
                ))}
              </ul>
            )}
            <div className="textboxContainer">
              <Button
                text="Reset Password"
                type="success"
                className="addUser"
                useSubmitBehavior={true}
                onClick={(e) =>  handleSubmit()}
              />
            </div>
          </section>
        </Popup>
      )}
    </>
  );
};
export default ResetPasswordPopup;

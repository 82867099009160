import React, {useCallback, useEffect, useState} from "react";
import ButtonBox from "devextreme-react/button";
import {Column, DataGrid, SearchPanel,} from "devextreme-react/data-grid";
import Loader from "../../Components/Loader";

import AddBlackoutPopup from "./AddBlackout/AddBlackout";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../Constants/values";
import EditBlackoutPopup from "./EditBlackout/EditBlackout";
import {confirm} from "devextreme/ui/dialog";
import "./Blackout.scss";
import BlackoutService from "../../Services/Blackout/BlackoutService";

type BlackoutProps = {
    startTime: any;
    endTime: any;
    countryIso2?: string;
}
const Blackout = () => {
    const [blackoutList, setBlackoutList] = useState<any[]>();
    const [loading, setLoading] = useState(false);
    const [isAddPopupVisible, setAddPopupVisibility] = useState(false);
    const [isEditPopupVisible, setEditPopupVisibility] = useState(false);

    const [blackoutID, setBlackoutID] = useState<string>("");
    const [blackoutDetails, setBlackoutDetails] = useState<any>({
        startTime: "",
        endTime: "",
        countryIso2: ""
    });

    const getBlackout = () => {
        setLoading(true)
        BlackoutService.getBlackout().then((response) => {
                const blackouts = response.result;
                setLoading(false)
                setBlackoutList(blackouts)
            }
        ).catch(() => {
            notify("Error while fetching blackout defaults", "error", TOASTER_DELAY);
            setLoading(false)
        })
    }

    const deleteBlackoutHandler = React.useCallback((e) => {
        let result = confirm(
            "<i>Are you sure you want to delete this blackout default? <b>",
            "Confirm blackout default deletion"
        );

        result.then((dialogResult) => {
            if (dialogResult) {
                BlackoutService.DeleteBlackout(e.row.data.id)
                    .then((r) => {
                        notify("Blackout default deleted successfully", "success", TOASTER_DELAY);
                    })
                    .catch(() => {
                        notify("Error while deleting blackout default.", "error", TOASTER_DELAY);
                    })
                    .finally(() => getBlackout());
            }
        });
    }, []);

    const showAddPopup = () => {
        setBlackoutDetails({
            startTime: "",
            endTime: "",
            countryIso2: "",
        });

        setBlackoutID("");

        setEditPopupVisibility(false);
        setAddPopupVisibility(true);
    }

    const showEditPopup = (element: any) => {
        const data = element.row.data;

        setBlackoutDetails({
            startTime: data.startTime,
            endTime: data.endTime,
            // countryIso2: data.countryIso2,
        });

        setBlackoutID(data.id);

        setAddPopupVisibility(false);
        setEditPopupVisibility(true);
    }

    const toggleAddPopupVisibility = () => {
        setAddPopupVisibility(!isAddPopupVisible);
    }

    const toggleEditPopupVisibility = () => {
        setEditPopupVisibility((prev) => !prev);
    }

    useEffect(() => {
        getBlackout();
    }, []);

    return (
        <div>
            <h1>Blackout</h1>
            <div>Manage blackout defaults here.</div>
            <ButtonBox
                text="Add Blackout"
                className="addBlackout"
                onClick={showAddPopup}
            ></ButtonBox>

            <DataGrid
                keyExpr={["id", "currencyIso3"]}
                id="gridContainer"
                className="grid-table"
                dataSource={blackoutList}
                showBorders={true}
            >
                <SearchPanel visible={true}/>
                <Column dataField="id" caption="ID" dataType="string"/>
                <Column dataField="startTime" caption="Start Time" dataType="string" cellRender={
                    (data: any) => {
                        return data.value + " GMT";
                    }
                }/>
                <Column dataField="endTime" caption="End Time" dataType="string" cellRender={
                    (data: any) => {
                        return data.value + " GMT";
                    }
                }/>
                <Column dataField="countryIso2" caption="Country" dataType="string"/>
                <Column dataField="createdOn" caption="Created On" format="dd/MM/yyyy" dataType="date"/>
                <Column dataField="lastUpdatedOn" caption="Last Update" format="dd/MM/yyyy" dataType="date"/>
                <Column type="buttons" buttons={
                    [
                        {
                            name: "editBlackout",
                            onClick: showEditPopup,
                            icon: "edit",
                        },
                        {
                            name: "deleteBlackout",
                            onClick: deleteBlackoutHandler,
                            icon: "trash",
                        },
                    ]
                } width="auto" allowEditing={false}></Column>
            </DataGrid>

            <Loader isLoaderVisible={loading}/>

            {isAddPopupVisible && <AddBlackoutPopup
                isAddPopupVisible={isAddPopupVisible}
                addPopupVisibilityHandler={toggleAddPopupVisibility}
                fetchBlackoutList={getBlackout}
                blackoutDetails={blackoutDetails}
                blackoutDetailsHandler={setBlackoutDetails}
                loadingHandler={setLoading}
            /> }

            {isEditPopupVisible && <EditBlackoutPopup
                blackoutID={blackoutID}
                isEditPopupVisible={isEditPopupVisible}
                editPopupVisibilityHandler={toggleEditPopupVisibility}
                fetchBlackoutList={getBlackout}
                blackoutDetails={blackoutDetails}
                blackoutDetailsHandler={setBlackoutDetails}
                loadingHandler={setLoading}
            />}

        </div>
    )
}

export default Blackout;
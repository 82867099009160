import Popup from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import {useState} from "react";
import Button from "devextreme-react/button";
import CreditService from "../../../Services/Credit/CreditService";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";

type Props = {
    showPopUp: boolean;
    popUpHandler: (showPopUp: boolean) => void;
    externalReference: string;
    fetchTransactions: () => void;
    fetchBalance: () => void;
    setLoading: (loading: boolean) => void;
};
const ManualCredit = ({showPopUp, popUpHandler,fetchBalance, externalReference, fetchTransactions, setLoading}: Props) => {
    const [creditInformation, setCreditInformation] = useState({
        credits: "",
        reference: "",
        note: ""
    });

    const onSubmit = () => {
        const payload = {
            userExternalReference: externalReference,
            credits: creditInformation.credits,
            reference: creditInformation.reference,
            note: creditInformation.note
        }

        if (payload.credits === "" || payload.reference === "") {
            notify("Please fill all required fields", "error", TOASTER_DELAY);
            return;
        }

        setLoading(true);
        CreditService.addManualCredit(payload).then((res) => {
            fetchTransactions();
            fetchBalance();
            popUpHandler(false);
            setCreditInformation({
                credits: "",
                reference: "",
                note: ""
            });
            setLoading(false);
        }).catch((err) => {
            const error = err?.response?.data?.Error?.Message || "";

            popUpHandler(false);
            setLoading(false);

            if (error && error !== "") {
                notify(error, "error", TOASTER_DELAY);
            } else {
                notify("Something went wring, Please try again later.", "error", TOASTER_DELAY);
            }
        })
    }

    return (
        <Popup
            width={660}
            height={540}
            showTitle={true}
            title="Add Credit"
            dragEnabled={false}
            visible={showPopUp}
            onHiding={() => popUpHandler(false)}
        >
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Credit Amount"
                        value={creditInformation.credits}
                        onValueChange={(e) => setCreditInformation({...creditInformation, credits: e})}
                    >
                        <Validator>
                            <RequiredRule message="Credit is required"/>
                            <PatternRule
                                message="Invalid credit"
                                pattern={/^[0-9]*$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Reference"
                        value={creditInformation.reference}
                        onValueChange={(e) => setCreditInformation({...creditInformation, reference: e})}
                    >
                        <Validator>
                            <RequiredRule message="Reference is required"/>
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Note"
                        value={creditInformation.note}
                        onValueChange={(e) => setCreditInformation({...creditInformation, note: e})}
                    >
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <Button
                        text="Add Credit"
                        type="success"
                        className="addUser"
                        useSubmitBehavior={true}
                        onClick={(e) => onSubmit()}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default ManualCredit;
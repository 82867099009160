import envConfig from '../../configs/env.config';
import './AppVersion.scss';

type Props = {};

export const AppVersion = (props: Props) => {
  return (
    <section id="AppVersion" className="app-version">
      <p className="app-version__text">
        Version: {envConfig.REACT_APP_VERSION}
      </p>
    </section>
  );
};

import React from "react";
import { useEffect, useState } from "react";

import notify from "devextreme/ui/notify";
import {
  DataGrid,
  Button,
  Column,
  Editing,
  LoadPanel as GridLoadPanel,
} from "devextreme-react/data-grid";
import ButtonBox from "devextreme-react/button";
import { confirm } from "devextreme/ui/dialog";

import AddRolePopup from "./AddRole";

import RolesService from "../../Services/Roles/Roles";

import Loader from "../../Components/Loader";

// import "devextreme/dist/css/dx.light.css";
import "./Roles.scss";

function Roles() {
  const [isLoadPanelVisible, changeLoadPanelVisible] = useState(false);

  const [showAddEditPanel, setShowAddEditPanel] = useState(false);
  const [rolesList, setRolesList] = useState<any[]>();

  const [roleName, setRoleName] = useState("");

  useEffect(() => {
    getRolesData();
  }, [1]);

  const getRolesData = () => {
    changeLoadPanelVisible(true);
    RolesService.GetAllRoles()
      .then((result: string[]) => {
        let results = result.map((str, index) => ({
          name: str,
          id: index + 1,
        }));
        setRolesList(results);
        changeLoadPanelVisible(false);
      })
      .catch((e) => {
        console.log(e);
        changeLoadPanelVisible(false);
      });
  };

  function addRoleDone(message: string, isError: boolean) {
    if (!isError) getRolesData();

    notify(message, isError ? "error" : "success", 5000);
  }

  const DeleteRole = React.useCallback((e) => {
    if (e.row.data.name == "Administrator")
      notify("Administrator role cannot be deleted!", "error", 3000);
    else {
      let result = confirm(
        "<i>Are you sure you want to delete role <b>" +
          e.row.data.name +
          "</b>?</i>",
        "Confirm Role Deletion"
      );

      result.then((dialogResult) => {
        if (dialogResult) {
          RolesService.DeleteRole(e.row.data.name)
            .then((r) => {
              notify(e.row.data.name + " deleted successfuly", "success", 3000);
            })
            .catch((e) => {
              notify(
                "Failed deleting " + e?.row?.data?.name || "" + ": " + e,
                "error",
                3000
              );
            })
            .finally(() => getRolesData());
        }
      });
    }
  }, []);

  function handlePopupHidden() {
    setShowAddEditPanel(false);
  }

  function showAddPopup() {
    setShowAddEditPanel(true);
  }

  function handlePanel() {
    return (
      <div>
        <h1>Roles</h1>
        <div>Manage the roles in your organisations from here.</div>

        <ButtonBox
          text="Add Role"
          className="addRole"
          onClick={showAddPopup}
        ></ButtonBox>

        <DataGrid
          keyExpr="name"
          id="gridContainer"
          dataSource={rolesList}
          showBorders={true}
        >
          <GridLoadPanel enabled={false} />
          <Editing mode="row" useIcons={true} allowDeleting={true} />
          <Column dataField="name" caption="name"/>
          <Column type="buttons">
            <Button name="delete" onClick={DeleteRole} />
            <Button name="save" />
          </Column>
        </DataGrid>

        <Loader isLoaderVisible={isLoadPanelVisible} />

        {AddRolePopup(
          showAddEditPanel,
          handlePopupHidden,
          addRoleDone,
          roleName,
          setRoleName
        )}
      </div>
    );
  }

  return <div id="clientGrid">{handlePanel()}</div>;
}

export default Roles;

import {useEffect, useState} from 'react';
import {Heading} from '../../../Components/Heading/Heading';

import GameResultReportExportBtn from './GameResultReportExport/GameResultReportExportBtn';
import GameResultReportFilter from './GameResultReportFilter/GameResultReportFilter';
import GameResultReportGrid from './GameResultReportGrid/GameResultReportGrid';
import './GameResultReport.scss';
import {GameResultReportPayloadDTO} from "../../../Models/Reports/GameResultReport";
import {PaginationResponseDto} from "../../../Models/PaginationResponseDto";
import ReportingService from "../../../Services/Reporting/ReportingService";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";

type Props = {};

export const GameResultReport = (props: Props) => {
    const [gameResultData, setGameResultData] = useState<any>([]);
    const [Loading, setLoading] = useState(false);

    const [paginationData, setPaginationData] = useState<PaginationResponseDto>({
        totalPages: 0,
        pageNumber: 1,
    });

    const [gameResulPayload, setGameResulLPayload] = useState<GameResultReportPayloadDTO>({
        pageSize: 10,
        startDate: "2024-05-21T11:32:26.205Z",
        endDate: "2024-10-21T11:32:26.205Z"
    });

    const getGameReportHandler = (pageNumber: number = 1) => {
        setLoading(true);

        const payload: GameResultReportPayloadDTO = {
            pageNumber: pageNumber,
            ...gameResulPayload
        }

        ReportingService.getGameResultReport(payload).then((res) => {
            setGameResultData(res.result);

            setPaginationData({
                ...paginationData,
                totalRecords: res?.totalItemCount,
                totalPages: res?.totalPageCount
            });

            setLoading(false);
        }).catch((err) => {
            notify('Error while fetching Game Result', 'error', TOASTER_DELAY);
            setLoading(false);
        });
    }

    const handleFilterGameReport = () => {
        setLoading(true);

        const payload: GameResultReportPayloadDTO = {
            pageNumber: paginationData.pageNumber,
            ...gameResulPayload
        }

        ReportingService.getGameResultReport(payload)
            .then((res) => {
                setGameResultData(res.result);

                setPaginationData({
                    ...paginationData,
                    totalRecords: res?.totalItemCount,
                    totalPages: res?.totalPageCount
                });

                setLoading(false);
            })
            .catch((err) => {
                notify('Error while fetching Game Result', 'error', TOASTER_DELAY);
                setLoading(false);
            });
    };

    useEffect(() => {
        getGameReportHandler();
    }, []);

    return (
        <>
            <section className="topup">

                <div
                    style={{
                        display: 'flex',
                        gap: '1.5rem',
                        alignItems: 'center',
                        margin: '1rem 0 1.5rem',
                    }}
                >
                    <Heading
                        level={2}
                        textContent="Game Results Report"
                        style={{margin: '0'}}
                    />
                    {gameResultData.length > 0 && (
                        <GameResultReportExportBtn
                            gameResults={gameResulPayload}
                            paginationData={paginationData}
                        />
                    )}
                </div>

                <GameResultReportFilter
                    gameResultPayload={gameResulPayload}
                    setgameResultPayload={setGameResulLPayload}
                    isLoading={Loading}
                    handleFilterGameReport={handleFilterGameReport}
                />

                <GameResultReportGrid
                    gameResults={gameResultData}
                    paginationData={paginationData}
                    getGameReportHandler={getGameReportHandler}
                />
            </section>
        </>
    );
};

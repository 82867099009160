class storageHandler {
  SaveToLocalStorage(key: string, data: any) {
    localStorage.removeItem(key);
    localStorage.setItem(key, data);
  }
  Get(key: string) {
    return localStorage.getItem(key);
  }
  Delete(key: string) {
    return localStorage.removeItem(key);
  }
  Clear() {
    localStorage.clear();
  }
}
export default new storageHandler();
import jwt_decode, { JwtPayload } from 'jwt-decode';
import storageHandler from './StorageHandler';
class TokenHandler {
  GetToken() {
    return storageHandler.Get('token');
  }
  GetRefreshToken() {
    return storageHandler.Get('refreshToken') ?? '';
  }
  ClearAll() {
    storageHandler.Delete('token');
    storageHandler.Clear();
  }

  GetTokenObject() {
    const token = storageHandler.Get('token');
    if (token) {
      let isValidToken = this.validateToken(token);

      if (isValidToken) {
        return JSON.parse(token);
      }
    }

    storageHandler.Clear();
  }

  private validateToken(token: string) {
    try {
      let decodedToken = jwt_decode<JwtPayload>(token);
      let dateNow = new Date();
      const expToken = Number(decodedToken.exp) * 1000;
      const currentTime = dateNow.getTime();
      return expToken > currentTime;
    } catch {
      storageHandler.Clear();
      return false;
    }
  }

  setToken(token: string) {
    const isValidToken = this.validateToken(token);
    if (isValidToken) {
      storageHandler.SaveToLocalStorage('token', token);
    }
  }
  setRefreshToken(token: string) {
    storageHandler.SaveToLocalStorage('refreshToken', token);
  }
}
export default new TokenHandler();

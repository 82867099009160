import {Button, CheckBox, TextArea, TextBox} from "devextreme-react";
import {useState} from "react";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../../Constants/values";

type RuleType = {
    name: string;
    regularExpression: string;
    verificationRegularExpression: string;
    odd: string;
    minOdd: string;
    templateName: string;
    inSimulationMode: boolean;
}

type AddRuleProps = {
    setIsAddRule: (arg1: boolean) => void;
    rules: any[];
    updateRuleHandler: (rule: any) => void;
}

const AddRule = ({setIsAddRule, rules, updateRuleHandler}: AddRuleProps) => {
    const [addRule, setAddRule] = useState<RuleType>({
        name: "",
        regularExpression: "",
        verificationRegularExpression: "",
        odd: "",
        minOdd: "",
        templateName: "",
        inSimulationMode: false,
    });

    const addRuleHandler = () => {
        if (
            !addRule.name ||
            !addRule.odd ||
            !addRule.regularExpression ||
            !addRule.verificationRegularExpression ||
            !addRule.templateName
        ) {
            notify("Please fill all fields", "error", TOASTER_DELAY);
            return;
        }

        if (addRule.templateName.length < 5) {
            notify("Template name must be at least 5 characters", "error", TOASTER_DELAY);
            return;
        }

        if (!/^\d*\.?\d*$/.test(addRule.odd)) {
            notify("Odd must be a valid number", "error", TOASTER_DELAY);
            return;
        }

        if (addRule.name.length < 5) {
            notify("Name must be at least 5 characters", "error", TOASTER_DELAY);
            return;
        }

        if (addRule.name.length > 100) {
            notify(
                "Name must not be more than 100 characters",
                "error",
                TOASTER_DELAY
            );
            return;
        }

        let updatedRules = [...rules[0].betsRules];

        updatedRules.push(addRule);

        const updatedRule = {
            ...rules[0],
            betsRules: updatedRules,
        };

        updateRuleHandler(updatedRule);

        return;
    }

    return (
        <div className="rule-form-container">
            <label className="form-label">
                Rule Name:
            </label>
            <div className="rule-name-input">
                <TextBox
                    value={addRule?.name || ""}
                    style={{
                        width: "100%",
                    }}
                    onValueChanged={(e) => {
                        setAddRule({
                            ...addRule,
                            name: e.value,
                        });
                    }}
                />
            </div>

            <label className="form-label">
                Odd:
            </label>
            <TextBox
                value={addRule?.odd || ""}
                style={{
                    width: "100%",
                }}
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        odd: e.value,
                    });
                }}
            />

            <label className="form-label">
                Minimum Odd:
            </label>
            <TextBox
                value={addRule?.minOdd || ""}
                style={{
                    width: "100%",
                }}
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        minOdd: e.value,
                    });
                }}
            />

            <label className="form-label">
                Template Name:
            </label>
            <TextBox
                value={addRule?.templateName || ""}
                style={{
                    width: "100%",
                }}
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        templateName: e.value,
                    });
                }}
            />

            <label className="form-label">
                Expression:
            </label>
            <TextArea
                height={220}
                value={addRule?.regularExpression || ""}
                className="expression-textarea"
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        regularExpression: e.value,
                    });
                }}
            />

            <label className="form-label">
                Verification:
            </label>
            <TextArea
                height={220}
                value={addRule?.verificationRegularExpression || ""}
                className="expression-textarea"
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        verificationRegularExpression: e.value,
                    });
                }}
            />

            <label className="form-label">
                Simulation Mode:
            </label>
            <CheckBox
                value={addRule?.inSimulationMode || false}
                text={`In simulation mode (${addRule?.inSimulationMode ? "Yes" : "No"})`}
                onValueChanged={(e) => {
                    setAddRule({
                        ...addRule,
                        inSimulationMode: e.value,
                    });
                }}
            />

            <div>
                <Button
                    type="default"
                    text="Add Rule"
                    style={{
                        marginTop: "1rem",
                    }}
                    onClick={() => addRuleHandler()}
                />
                <Button
                    type="danger"
                    text="Cancel"
                    style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                    }}
                    onClick={() => {
                        setIsAddRule(false);
                    }}
                />
            </div>

        </div>
    )
}

export default AddRule
import {Button, DateBox, TagBox, Validator} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import Loader from '../../../../Components/Loader';
import {COUNTRY_CODES} from '../../../../Constants/values';
import {TopupReportPayloadDTO} from '../../../../Models/TopupReport/TopupReport';
import checkValidation from '../../../../Utils/FormValidation';
import '../RevenueReport.scss';
import TextBox from "devextreme-react/text-box";

type Props = {
    revenueReportPayload: TopupReportPayloadDTO;
    setRevenueReportPayload: React.Dispatch<TopupReportPayloadDTO>;
    loading: boolean;
    handleFilterRevenueReport: () => void;
    searchKey: string;
    setSearchKey: React.Dispatch<string>;
};

const RevenueReportFilter = ({
                                 revenueReportPayload,
                                 setRevenueReportPayload,
                                 loading: Loading,
                                 handleFilterRevenueReport,
                                 searchKey,
                                 setSearchKey
                             }: Props) => {


    return (
        <article className="topup__filter">
            <div className="topup__filter-fields">

                <TagBox
                    items={COUNTRY_CODES}
                    width={'100%'}
                    displayExpr={'name'}
                    valueExpr={'code'}
                    inputAttr={{
                        id: 'BillingStatus',
                        name: 'BillingStatus',
                    }}
                    placeholder="Select Tax country"
                    searchEnabled
                    hideSelectedItems
                    className="topup__client-selectBox"
                    onValueChange={(e) => {
                        setRevenueReportPayload({
                            ...revenueReportPayload,
                            taxCountries: e,
                        });
                    }}
                />


                <DateBox
                    id="topup__startDate"
                    placeholder="Date from"
                    inputAttr={{
                        id: 'transactions-startDate',
                    }}
                    // displayFormat="dd/MM/yyyy"
                    value={revenueReportPayload.startDate ?? undefined}
                    onValueChanged={(e) => {
                        const payload: TopupReportPayloadDTO = {
                            ...revenueReportPayload,
                            startDate: e.value,
                        };
                        setRevenueReportPayload(payload);
                    }}
                >
                    <Validator validationGroup="TopupValidator">
                        <RequiredRule message="Start Date is required"/>
                    </Validator>
                </DateBox>
                <DateBox
                    id="topup__endDate"
                    placeholder="Date to"
                    inputAttr={{
                        id: 'transactions-endDate',
                    }}
                    value={revenueReportPayload.endDate ?? undefined}
                    onValueChanged={(e) => {
                        const payload: TopupReportPayloadDTO = {
                            ...revenueReportPayload,
                            endDate: e.value,
                        };
                        setRevenueReportPayload(payload);
                    }}
                >
                    <Validator validationGroup="TopupValidator">
                        <RequiredRule message="End Date is required"/>
                    </Validator>
                </DateBox>
            </div>

            <Button
                style={{marginTop: '15px'}}
                text="Search"
                type="default"
                id="transactionsSearch"
                useSubmitBehavior={true}
                validationGroup="TopupValidator"
                stylingMode="contained"
                disabled={
                    !revenueReportPayload.startDate && !revenueReportPayload.endDate
                }
                onClick={(e) => checkValidation(e, handleFilterRevenueReport)}
            />

            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
                backgroundColor: '#f5f5f5',
                padding: '10px',
            }}>
                <TextBox
                    value={searchKey}
                    placeholder={'Search by user'}
                    onValueChanged={(e) => {
                        const searchKeyVal = e.value;
                        setSearchKey(searchKeyVal);
                        setRevenueReportPayload({
                            ...revenueReportPayload,
                            keyword: searchKeyVal,
                        });
                    }}
                />
            </div>
            <Loader isLoaderVisible={Loading}/>
        </article>
    );
};

export default RevenueReportFilter;

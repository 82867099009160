import { IServiceProvider } from "./IServiceProvider";

export class FactoryService {
  constructor() {
  }

  async create<T extends IServiceProvider>(type: (new () => T)): Promise<T> {
    let svc = new type();

    await svc.initialize();

    return svc as T;
  }
}

export default FactoryService;
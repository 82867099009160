import {Button} from 'devextreme-react';
import {useEffect, useState} from 'react';
import {PaginationResponseDto} from '../../Models/PaginationResponseDto';
import './PaginationComponent.scss';

interface Props {
    paginationData: PaginationResponseDto | undefined;
    gotoPage?: (pageNumber: number) => void | undefined;
}

const Pagination = ({paginationData, gotoPage}: Props) => {
    const [row, setRow] = useState<JSX.Element[]>([]);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        showPaging();
    }, [paginationData, pageNumber]);

    const nextPage = (pageNumber: number) => {
        if (paginationData) {
            pageNumber =
                pageNumber < paginationData.totalPages
                    ? pageNumber
                    : paginationData.totalPages;
        } else {
            pageNumber = 1;
        }
        setPageNumber(pageNumber);
    };

    const previousPage = (pageNumber: number) => {
        if (paginationData) {
            pageNumber = pageNumber <= 0 ? 5 : pageNumber;
        } else {
            pageNumber = 1;
        }
        setPageNumber(pageNumber);
    };

    const gotoFirstPage = () => {
        gotoPage?.(1);
        setPageNumber(1);
    };

    const gotoLastPage = (page: number) => {
        gotoPage?.(page);
        setPageNumber(page);
    };

    const showPaging = () => {
        let currentPage = pageNumber;
        let lastPage = 0;
        const totalPages = paginationData?.totalPages ?? 0;

        if (paginationData && totalPages > 1) {
            let rows: JSX.Element[] = [];

            if (currentPage == 1) {
                rows.push(
                    <Button
                        key="disabledPrevious"
                        type="normal"
                        icon="chevronleft"
                        disabled={true}
                    />
                );
            } else {
                rows.push(
                    <Button
                        key={'previous'}
                        type="normal"
                        icon="chevronleft"
                        onClick={() => previousPage(currentPage - 5)}
                    />
                );
            }
            if (currentPage > 1) {
                rows.push(
                    <div className="firstPage" key="disabledFirstPage">
                        <Button type="normal" text={`1`} onClick={() => gotoFirstPage()}/>
                        <div>&nbsp;...&nbsp;</div>
                    </div>
                );
            }

            for (let index = currentPage; index < currentPage + 5; index++) {
                if (index <= paginationData.totalPages) {
                    rows.push(
                        <Button
                            type="normal"
                            text={index.toString()}
                            className={index == paginationData?.pageNumber ? 'selected' : ''}
                            key={index + 1}
                            onClick={() => gotoPage && gotoPage(index)}
                        />
                    );
                }
                lastPage = index;
            }

            if (currentPage < paginationData.totalPages) {
                rows.push(
                    <div className="lastPage" key={'lastPage'}>
                        <div>&nbsp;...&nbsp;</div>
                        <Button
                            key="disabledLastPage"
                            type="normal"
                            text={`${paginationData?.totalPages}`}
                            onClick={() =>
                                gotoLastPage(
                                    paginationData?.totalPages ?? paginationData?.pageNumber
                                )
                            }
                        />
                    </div>
                );
            }

            if (lastPage > paginationData.totalPages) {
                rows.push(
                    <Button
                        key="disabledNext"
                        type="normal"
                        icon="chevronright"
                        disabled={true}
                    />
                );
            } else {
                rows.push(
                    <Button
                        key={'next'}
                        type="normal"
                        icon="chevronright"
                        onClick={() => nextPage(lastPage + 1)}
                    />
                );
            }

            setRow(rows);
        } else {
            setRow([]);
        }
    };
    return (
        <div className="paging-section">
            <div></div>
            <div className="paging-buttons">{row} </div>
            <div className="total-records">
                Total Records: <b>{paginationData?.totalRecords ?? 0}</b>, Total Page:{' '}
                <b>{paginationData?.totalPages ?? 0}</b>
            </div>
        </div>
    );
};
export default Pagination;

import FactoryService from "../FactoryService";
import TipBaseService from "../TipBaseService";

const factoryService = new FactoryService();

class SimulationService {
    private SIMULATOR_ENDPOINT = "MatchSimulator";

    async getMatchSimulator() {
        const response = await (
            await factoryService.create(TipBaseService)
        ).getAsJson(this.SIMULATOR_ENDPOINT, true);
        return response;
    }

    async updateMatchSimulator(payload: any) {
        const response = await (
            await factoryService.create(TipBaseService)
        ).patchAsJson(this.SIMULATOR_ENDPOINT, payload, true);
        return response;
    }

    async simulate(payload: any) {
        const response = await (
            await factoryService.create(TipBaseService)
        ).postAsJson(`${this.SIMULATOR_ENDPOINT}/simulation`, payload, true);
        return response;
    }


}

export default new SimulationService();

import FactoryService from "../FactoryService";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class RolesService {
  private ENDPOINT = "roles";

  async GetAllRoles() {
    var result = await (
      await factoryService.create(AuthBaseService)
    ).getAsJson(this.ENDPOINT, true);
    if (result) {
      return result;
    }
    return null;
  }

  async AddRole(role: string) {
    var result = await (
      await factoryService.create(AuthBaseService)
    ).postAsJson(this.ENDPOINT + '/' + role, null, true);
    if (result) {
      return result;
    }
    return null;
  }

  async DeleteRole(role: string) {
    var result = await (
      await factoryService.create(AuthBaseService)
    ).delete(this.ENDPOINT + "/" + role, true);
    if (result) {
      return result;
    }
    return null;
  }
}

export default new RolesService();

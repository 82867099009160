import "./Login.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoginDto } from "../../Models/Login/LoginDto";
import {
  default as auth,
  default as AuthService,
} from "../../Services/Auth/AuthService";

import Button from "devextreme-react/button";
import { LoadPanel } from "devextreme-react/load-panel";
import TextBox from "devextreme-react/text-box";
import Validator, { EmailRule, RequiredRule } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import mainLogo from "../../Assets/images/InPlayTips.png";

interface Props {}
function Login() {
  const afterLoginRoute: string = "/dashboard";

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isLoadPanelVisible, changeLoadPanelVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.isAuthenticated())
      navigate(afterLoginRoute, { replace: true });
  }, [3]);

  const gotoEmailValidator = () => navigate(afterLoginRoute, { replace: true });

  const signIn = () => {
    changeLoadPanelVisible(true);

    var data: LoginDto = {
      grant_type: "password",
      username: loginUsername,
      password: loginPassword,
    };
    auth
      .login(data, gotoEmailValidator)
      .then(() => navigate(afterLoginRoute, { replace: true }))
      .catch((err) => notify("Invalid credentials ", "error", 5000))
      .finally(() => changeLoadPanelVisible(false));
  };

  return (
    <div id="login-main" className="login-main">
      <section
        className="login-main__header"
        style={{
          backgroundImage: `url(${mainLogo.replace("./", "/")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        {/* <img
          className="login-main__logo"
          src={mainLogo.replace("./", "/")}
          alt="InplayTips Logo"
        /> */}
        <h4>In Play Tips</h4>
      </section>
      <form className="login-main__form" onSubmit={(e) => e.preventDefault()}>
        <h1 className="login-main__title">Inplaytips Administration Portal</h1>
        {/* <h2 className="login-main__form-title">Sign in</h2> */}
        <div className="login-main__field-container">
          <label htmlFor="login_email">E-Mail</label>
          <TextBox
            id="loginEmail"
            onValueChange={(e) => {
              setLoginUsername(e);
            }}
            inputAttr={{
              id: "login_email",
              name: "login_email",
            }}
          >
            <Validator>
              <RequiredRule message="E-Mail is required" />
              <EmailRule />
            </Validator>
          </TextBox>
        </div>
        <div className="login-main__field-container">
          <label htmlFor="login_password">Password</label>
          <TextBox
            id="loginPassword"
            mode="password"
            inputAttr={{
              id: "login_password",
            }}
            onValueChange={(e) => {
              setLoginPassword(e);
            }}
            onEnterKey={(e) => signIn()}
          >
            <Validator>
              <RequiredRule message="Password is required" />
            </Validator>
          </TextBox>
        </div>
        <Button
          text="Sign in"
          className="login-main__cta"
          type="normal"
          id="login_submit"
          useSubmitBehavior={true}
          onClick={(e) => signIn()}
        />
        <section className="login-main__footer">
          <hr />
          <p className="login-main__copyright">
            &copy; InplayTips {new Date()?.getFullYear()}
          </p>
        </section>
      </form>
      <LoadPanel
        visible={isLoadPanelVisible}
        shading={true}
        shadingColor="rgba(0, 0, 0, 0.2)"
      />
    </div>
    // <div id="login-main">
    //   <div className="login-header">
    //     <div id="logo"></div>
    //     <h1>Sign in</h1>
    //     <p>Gainchanger Authentication Administration Portal</p>
    //   </div>
    //   <label>E-Mail:</label>
    //   <div className="col-md-2">
    //     <TextBox
    //       onValueChange={(e) => {
    //         setLoginUsername(e);
    //       }}
    //     >
    //       <Validator>
    //         <RequiredRule message="E-Mail is required" />
    //         <EmailRule />
    //       </Validator>
    //     </TextBox>
    //   </div>
    //   &nbsp;
    //   <label>Password:</label>
    //   <div className="col-md-2">
    //     <TextBox
    //       mode="password"
    //       onValueChange={(e) => {
    //         setLoginPassword(e);
    //       }}
    //       onEnterKey={(e) => signIn()}
    //     >
    //       <Validator>
    //         <RequiredRule message="Password is required" />
    //       </Validator>
    //     </TextBox>
    //   </div>
    //   &nbsp;
    //   <Button
    //     text="Sign in"
    //     className="btn-login"
    //     type="normal"
    //     useSubmitBehavior={true}
    //     onClick={(e) => signIn()}
    //   />
    //   &nbsp;
    //   <div className="login-footer">
    //     <hr />
    //     <small className="login-footer">
    //       &copy; GainChanger 2022
    //       <div>
    //         <a href="/privacy-policy">Privacy Policy</a>
    //         &nbsp;|&nbsp;
    //         <a href="/terms-of-service">Terms of Service</a>
    //         &nbsp;|&nbsp;
    //         <a href="/contact">Contact</a>
    //       </div>
    //     </small>
    //   </div>
    // </div>
  );
}

export default Login;

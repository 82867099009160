import React from "react";

interface Props {}
export const TermsOfService: React.FC<Props> = () => {
  return (
    <div>
      <h1>Terms of Service Page</h1>
    </div>
  );
};

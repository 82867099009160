import "./ChangePassword.scss";
import Button from "devextreme-react/button";
import {CompareRule} from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import Validator, {RequiredRule} from "devextreme-react/validator";
import {useState} from "react";
import {useNavigate} from "react-router";
import {ChangePasswordDto} from "../../Models/Account/ChangePasswordDto";
import AuthService from "../../Services/Auth/AuthService";
import {IToastOptions, Toast} from "devextreme-react/toast";
import checkPasswordValidation from "../../Utils/PasswordValidation";
import AccountService from "../../Services/Account/AccountService";

interface Props {
}

function ChangePassword() {
    const [toastConfig, setToastConfig] = useState<IToastOptions>({
        visible: false,
        message: "",
        type: "info",
    });

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [validationMessage, setValidationMessage] = useState<string[]>([]);
    const navigate = useNavigate();

    const submitButtonHandler = () => {
        if (currentPassword && newPassword && confirmNewPassword) {
            return false;
        }

        return true
    }
    const changePassword = () => {
        let changePasswordModel: ChangePasswordDto = {
            currentPassword: currentPassword,
            newPassword: newPassword,
        };

        AccountService.ChangePassword(changePasswordModel).then((res) => {
            setToastConfig({
                ...toastConfig,
                visible: true,
                message: "Password changed successfully.",
                type: "success",
            });
        }).catch((err) => {
            console.log(err.message)
            setToastConfig({
                ...toastConfig,
                visible: true,
                message: err,
                type: "error",
            });
        });
    };

    return (
        <div id="form">
            <div>
                <TextBox
                    placeholder="Current Password"
                    mode="password"
                    onValueChange={(e) => {
                        setCurrentPassword(e);
                    }}
                >
                    <Validator>
                        <RequiredRule message="Password is required"/>
                    </Validator>
                </TextBox>
            </div>
            <div>
                <TextBox
                    placeholder="New Password"
                    mode="password"
                    onValueChange={(e) => {
                        setNewPassword(e);
                    }}
                >
                    <Validator>
                        <RequiredRule message="New is required"/>
                    </Validator>
                </TextBox>
            </div>
            <div>
                <TextBox
                    placeholder="Confirm New Password"
                    mode="password"
                    onValueChange={(e) => {
                        setConfirmNewPassword(e);
                    }}
                >
                    <Validator>
                        <RequiredRule message="Confirm New Password is required"/>
                        <CompareRule
                            message={"New Password and Confirm Password do not match "}
                            comparisonTarget={() => newPassword}
                        />
                    </Validator>
                </TextBox>
            </div>
            <div>
                <Button
                    text="Change Password"
                    className="btn-login"
                    type="normal"
                    disabled={submitButtonHandler()}
                    useSubmitBehavior={true}
                    onClick={changePassword}
                />
            </div>
            {validationMessage.length > 0 && (
                <ul className="validation-message">
                    {validationMessage.map((msg: string) => (
                        <li className="validation-message__item">{msg}</li>
                    ))}
                </ul>
            )}
            <Toast
                visible={toastConfig.visible}
                message={toastConfig.message}
                type={toastConfig.type}
                displayTime={3000}
                onHiding={onHiding}
            />
        </div>
    );

    function onHiding() {
        setToastConfig({...toastConfig, visible: false});
    }
}

export default ChangePassword;

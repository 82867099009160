import {Column} from 'devextreme-react/data-grid';
import {Grid} from '../../../../Components/Grid/Grid';
import {COUNTRY_CODES} from '../../../../Constants/values';
import Pagination from "../../../../Components/Grid/PaginationComponent";
import React from "react";

type Props = {
    paymentReportData: Array<any>;
    paginationData: any;
    handleFilterPaymentReport: (pageNumber: number) => void;
};

const PaymentsReportGrid = ({
                                paymentReportData,
                                paginationData,
                                handleFilterPaymentReport
                            }: Props) => {
    const gridColumns = [
        <Column
            key="date"
            dataField={'date'}
            caption={'Date'}
            alignment={'left'}
            cellRender={(cellData) => {
                const createdOn = cellData.data.createdOn;
                const date = new Date(createdOn);
                return <>{date.toLocaleDateString('en-US')}</>;
            }}
        />,
        <Column
            key="time"
            dataField={'time'}
            caption={'Time'}
            alignment={'left'}
            cellRender={(cellData) => {
                const createdOn = cellData.data.createdOn;
                const date = new Date(createdOn);
                return <>{date.toLocaleTimeString('en-US', {hour12: false})}</>;
            }}
        />,

        <Column
            key="user"
            dataField={'user'}
            caption={'User'}
            alignment={'left'}
            width={150}
            cellRender={(cellData) => {
                const firstName = cellData.data.firstName;
                const lastName = cellData.data.lastName;
                const phoneNumber = cellData.data.phoneNumber;

                return <>{`${firstName} ${lastName}` || phoneNumber}</>;
            }}
        />,

        <Column
            key="taxCountryIso2"
            dataField={'taxCountryIso2'}
            caption={'Tax country'}
            alignment={'left'}
            cellRender={(cellData) => {
                const taxCountry = cellData.data.taxCountryIso2;
                const countryName =
                    COUNTRY_CODES.find((c) => c.code === taxCountry)?.name || 'N/A';
                return <>{countryName}</>;
            }}
        />,

        <Column
            key="amount"
            dataField={'amount'}
            caption={'Amount'}
            alignment={'left'}
            cellRender={(cellData) => {
                const signedAmount = cellData.data.signedAmount;
                const currencyIso3 = cellData.data.currencyIso3;
                return <>{`${currencyIso3} ${signedAmount}`}</>;
            }}
            calculateSortValue={(rowData: { signedAmount: any; }) => rowData.signedAmount}
        />,
        <Column
            key="transactionStatusName"
            dataField={'transactionStatusName'}
            caption={'Status'}
            alignment={'left'}
        />,
        <Column
            key="paymentProviderTransactionId"
            dataField={'paymentProviderTransactionId'}
            caption={'Provider Id'}
            alignment={'left'}
        />,
        <Column
            key="transactionExternalReference"
            dataField={'transactionExternalReference'}
            caption={'Reference'}
            alignment={'left'}
            width={300}
        />,
    ];

    return (
        <>
            <div key={'TopupGrid'}>
                <Grid
                    Data={paymentReportData}
                    customColumns={gridColumns}
                    defaultPagination = {false}
                />

                <Pagination
                    paginationData={paginationData}
                    gotoPage={handleFilterPaymentReport}
                />
            </div>
        </>
    );
};

export default PaymentsReportGrid;

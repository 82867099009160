import envConfig from "../configs/env.config";
import { BaseService } from "./BaseService";
import IServiceProvider from "./IServiceProvider";

class AccountingBaseService extends BaseService implements IServiceProvider {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }

    async initialize() {
        this.baseUrl = envConfig.REACT_APP_ACCOUNTING_API_BASE_URL;
    }
}

export default AccountingBaseService
;

import { ClickEvent } from 'devextreme/ui/button';

const checkValidation = async (e: ClickEvent, onSubmit: () => void) => {
    e.validationGroup?.validate();
    const isValid = e.validationGroup['_validationInfo'].result.isValid;
    if (!isValid) {
        // throw '';
        return undefined;
    } else {
        onSubmit();
    }
};

export default checkValidation;
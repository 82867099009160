import {SIDEBAR_ITEMS} from "../Constants/values";
import {ROUTES} from "../routes";
import {Column} from "devextreme-react/data-grid";
import React from "react";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

export const useSideBarItems = () => {
    const sidebarMenuItems = [
        {
            id: ROUTES.DASHBOARD,
            text: SIDEBAR_ITEMS.DASHBOARD,
            icon: "home",
            location: ROUTES.DASHBOARD,
        },
        // {
        //     id: ROUTES.USERS,
        //     text: SIDEBAR_ITEMS.CUSTOMERS,
        //     icon: "group",
        //     location: ROUTES.USERS,
        // },
        {
            id: ROUTES.BACKEND_USERS,
            text: SIDEBAR_ITEMS.BACKEND_USERS,
            icon: "group",
            location: ROUTES.BACKEND_USERS,
        },

        {
            id: ROUTES.BROADCAST,
            text: SIDEBAR_ITEMS.BROADCAST,
            icon: "importselected",
            location: ROUTES.BROADCAST,
        },
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            {
                id: ROUTES.CREDIT,
                text: SIDEBAR_ITEMS.CREDIT,
                icon: "money",
                location: ROUTES.CREDIT,
            },
        ] : [],
        {
            id: ROUTES.BLACKOUT,
            text: SIDEBAR_ITEMS.BLACKOUT,
            icon: "clock",
            location: ROUTES.BLACKOUT,
        },
        {
            id: ROUTES.ROLES,
            text: SIDEBAR_ITEMS.ROLES,
            icon: "key",
            location: ROUTES.ROLES,
        },
        {
            id: ROUTES.CLIENTS,
            text: SIDEBAR_ITEMS.CLIENTS,
            icon: "user",
            location: ROUTES.CLIENTS,
        },
        {
            id: ROUTES.ACCOUNT,
            text: SIDEBAR_ITEMS.ACCOUNT,
            icon: "user",
            location: ROUTES.ACCOUNT,
        },
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            {
                id: "multilevel-1",
                text: SIDEBAR_ITEMS.RULE_ENGINE,
                icon: "detailslayout",
                expanded: false,
                selected: false,
                parentId: 2,
                hasItems: true,
                items: [
                    {
                        id: ROUTES.RULES,
                        text: SIDEBAR_ITEMS.RULES,
                        expanded: false,
                        selected: false,
                    },
                    {
                        id: ROUTES.SIMULATIONS,
                        text: SIDEBAR_ITEMS.SIMULATIONS,
                        expanded: false,
                        selected: false,
                    },
                ],
            },
        ] : [],
        {
            id: "multilevel-2",
            text: SIDEBAR_ITEMS.REPORTS,
            icon: "reports-icon",
            expanded: false,
            selected: false,
            parentId: 9,
            hasItems: true,
            items: [
                {
                    id: ROUTES.GAMERESULTSREPORT,
                    text: "Game Result",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.PAYMENTSREPORT,
                    text: "Payments",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.REVENUEREPORT,
                    text: "Revenue",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.CUSTOMERSREPORT,
                    text: "Customers",
                    expanded: false,
                    selected: false,
                }
            ],
        },
        {
            id: "multilevel-4",
            text: SIDEBAR_ITEMS.SETTINGS,
            icon: "preferences",
            expanded: false,
            selected: false,
            parentId: 9,
            hasItems: true,
            items: [
                {
                    id: ROUTES.RESET_CACHE,
                    text: SIDEBAR_ITEMS.RESET_CACHE,
                    expanded: false,
                    selected: false,
                },
            ],
        },
    ];

    return [sidebarMenuItems];
};

import FactoryService from "../FactoryService";
import ClientDto from "../../Models/Clients/ClientDto";
import AuthBaseService from "../AuthBaseService";

const factoryService = new FactoryService();

class ClientsService {
  private CLIENTS_ENDPOINT = "Clients";

  async GetList(): Promise<ClientDto[]> {
    const response = await (
      await factoryService.create(AuthBaseService)
    ).getAsJson(this.CLIENTS_ENDPOINT, true);
    return response;
  }
}

export default new ClientsService();

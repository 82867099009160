import React from 'react'

interface Props {

}
export const PrivacyPolicy: React.FC<Props> = () => {
        return (
            <div>
                <h1>Privacy Policy Page</h1>
            </div>
        );
}
import {Column} from 'devextreme-react/data-grid';
import {Grid} from '../../../../Components/Grid/Grid';
import Pagination from "../../../../Components/Grid/PaginationComponent";
import React from "react";
import {PaginationResponseDto} from "../../../../Models/PaginationResponseDto";

type Props = {
    gameResults: Array<any>;
    paginationData: PaginationResponseDto;
    getGameReportHandler: (pageNumber: number) => void;
};

const GameResultReportGrid = ({gameResults, paginationData, getGameReportHandler}: Props) => {
    const gridColumns = [
        <Column
            key="date"
            dataField={'date'}
            caption={'Date'}
            alignment={'left'}
        />,
        <Column
            key="time"
            dataField={'time'}
            caption={'Time'}
            alignment={'left'}
        />,
        <Column
            key="league"
            dataField={'league'}
            caption={'League'}
            alignment={'left'}
           
        />,
        <Column
            key="teams"
            dataField={'teams'}
            caption={'Teams'}
            alignment={'left'}
           
        />,
        <Column
            key="ruleapplied"
            dataField={'ruleApllied'}
            caption={'Rule applied'}
            alignment={'left'}
            
        />,
        <Column
            key="result"
            dataField={'result'}
            caption={'Result'}
            alignment={'left'}
            
        />,
       
    ];

    return (
        <>
            <div key={'gameResultsGrid'}>
                <Grid
                    Data={gameResults}
                    customColumns={gridColumns}
                    defaultPagination
                />
                <Pagination
                    paginationData={paginationData}
                    gotoPage={getGameReportHandler}
                />
            </div>
        </>
    );
};

export default GameResultReportGrid;
